import React, { useEffect, useState } from 'react'
import { faPen,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import usePost from '../../services/usePost';
import SOURCES from '../../utils/constants';
import { capitalizeFirstLetter } from '../../utils/functions';
import NoRecordsFound from '../common/noRecordsFound';
import Popup from '../common/popup';
import TableHeader from '../common/tableHeader';
import TableLoader from '../common/tableLoader';
import { MSG_ALERT } from "../../utils/message"

const AlertEmailSmsList = ({ 
    isLoading, 
    alerts, 
    getAlerts, 
    getAlert, 
    setShowForm, 
    setId ,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {
    const [deleteId, setDeleteId] = useState(0);
    const [toggleModal, setToggleModal] = useState(false);


    const { ALERT_VIA_EMAIL, ALERT_VIA_SMS, ALERT_VIA_BOTH } = SOURCES;

    const {
        callApi: deleteAlert,
        // isLoading: deleteAlertLoader,
        processSuccess: removeSuccess,
    } = usePost({
        endpoint: `alert/delete`,
    });


    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getAlerts();
        }
    }, [removeSuccess])

    const handleEdit = async (id) => {
        getAlert([{ key: "id", val: id }]);
        setShowForm(true)
        setId(id);
    };
    const removeAlert = async () => {
        const postData = {
            id: deleteId,
        };
        deleteAlert(postData, MSG_ALERT.DELETE);
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    return (
        <>
            <Table responsive striped bordered hover size="sm" className="shadow">
                <TableHeader
                    headers={[{ title: "Alert Via", showIcon: true }, { title: "Name", showIcon: true }, { title: "Email", showIcon: true }, { title: "Mobile No", showIcon: true }, { style: { width: "1%" } }]}
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <tbody>
                    {
                        isLoading ?
                            <TableLoader colSpan="5" />
                            :
                            <>
                                {alerts && alerts?.length > 0 ?
                                    alerts?.map((res, idx) => {
                                        return (
                                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                                <td>
                                                    {res?.alertVia === ALERT_VIA_BOTH ? capitalizeFirstLetter(ALERT_VIA_EMAIL) + ' / ' + capitalizeFirstLetter(ALERT_VIA_SMS) : capitalizeFirstLetter(res.alertVia)}
                                                </td>
                                                <td>
                                                    <Link onClick={() => {
                                                        handleEdit(res?._id);
                                                    }}>
                                                        {res?.alertName}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {res?.alertEmail}
                                                </td>
                                                <td>
                                                    {res?.alertSms}
                                                </td>

                                                <td>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            handleEdit(res?._id);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPen} className="m-2" />
                                                    </NavLink>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            setDeleteId(res?._id);
                                                            setToggleModal(true);
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            style={{ color: "#dc3545" }}
                                                            className="m-2"

                                                        />
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <NoRecordsFound colSpan="5" />
                                }
                            </>
                    }
                </tbody>
            </Table>
            {toggleModal && (
                <Popup
                    showPopup={toggleModal}
                    hidePopup={() => setToggleModal(false)}
                    actionMethod={removeAlert}
                    title="Delete Alert"
                    promtMessage="Are you sure you want to delete Alert?"
                />
            )}
        </>
    )
}

export default AlertEmailSmsList