import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "react-time-picker";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Row,
  Col,
  Table,
  CardFooter,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import usePost from "../../services/usePost";
import { useKey } from "../../services/useKey";
import AlertEmailSms from "./../alertEmailSms";
import ModalDialog from "./../common/modal";
import TableHeader from "../common/tableHeader";
import GenericTabs from "../common/tab";
import SOURCES from "../../utils/constants";
import {
  capitalizeFirstLetter,
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../utils/functions";
import { MSG_LEADSOURCE } from "../../utils/message";
import ValidationErrorHandling from "../common/validationErrorHandling";
import SubmitButton from "../common/submitButton";
import LocalStorageService from "../../utils/localStorageServices";
import TripleseatLocationModule from "./modules/leadSource/tripleseatLocation";
import MailChimpCampaignsModules from "./modules/leadSource/mailChimpCampaigns";

const validationSchema = yupValidationSchema({
  leadSourceSelected: { required: true, customName: "Lead source" },
  jobName: { required: true, customName: "Job name" },
  password: { required: true, customName: "Password" },
  username: {
    required: true,
    customName: "Lead source account username/email",
  },
  // leadProvider: { required: true, customName: "Select a lead provider" },
});
const localStorageServices = LocalStorageService.getService();

const LeadSourcesForm = ({ setShowForm, setId, getLeadSources, id = null }) => {
  const currentUser = localStorageServices.getCurrentUser();

  //const paymentPlanCode = currentUser?.user?.paymentPlanId?.planCode

  const [leadSourceSelected, setLeadSourceSelected] = useState("");
  const [autoresponderSelected, setAutoResponderSelected] = useState("");
  const [jobName, setJobName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [leadSourceActivate, setLeadSourceActivate] = useState(false);
  const [destinationId, setDestinationId] = useState([]);
  const [timezoneSelected, setTimezoneSelected] = useState(
    "America/Los_Angeles"
  );
  const [sendAlert, setSendAlert] = useState(false);
  const [alertSendType, setAlertSendType] = useState("");
  const [alertSendStartTime, setSendAlertStartTime] = useState("");
  const [alertSendEndTime, setSendAlertEndTime] = useState("");
  const [alertUserId, setAlertUserId] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [leadProvider, setLeadProvider] = useState("");
  const [extraInfo, setExtraInfo] = useState(null);
  const [errors, setErrors] = useState({});

  const [showCardTripleseatLocation, setShowCardTripleseatLocation] =
    useState(false);
  const [locationIdTripleseat, setLocationIdTripleseat] = useState(0);
  const [tripleSeatLocationsLoaded, setTripleSeatLocationsLoaded] =
    useState(false);

  const [showCardMailChimpCampaigns, setShowCardMailChimpCampaigns] =
    useState(false);
    const [campaignIdMailChimp, setCampaignIdMailChimp] = useState(0);
  const [mailChimpCampaignsLoaded, setMailChimpCampaignsLoaded] =
    useState(false);

  const [toggleAutoresponderModal, setToggleAutoresponderModal] =
    useState(false);
  const [toggleRecipientModal, setToggleRecipientModal] = useState(false);
  const encodedPassword = useMemo(
    () => encodeURIComponent(password),
    [password]
  );

  const { callApi: getLeadSourceDetail, data: leadSourceDetail } = useGet({
    endpoint: `leadsource/?id=${id}`,
  });

  const {
    callApi: getLeadSourcesOptions,
    data: leadSource,
    isLoading: getLeadSourcesOptionsLoader,
  } = useGet({
    endpoint: `leadsource/options`,
  });
  const {
    callApi: getDestinations,
    data: destinations,
    isLoading: getDestinationsLoader,
  } = useGet({
    endpoint: `integration`,
  });
  const { callApi: getAutoResponders, isLoading: getAutoRespondersLoader } =
    useGet({
      endpoint: `autoresponder`,
    });
  const {
    callApi: getAlertUsers,
    data: alertUsers,
    isLoading: getAlertUsersLoader,
  } = useGet({
    endpoint: `alert`,
  });
  const {
    callApi: getTimezones,
    data: timezones,
    isLoading: getTimezonesLoader,
  } = useGet({
    endpoint: `common/timezones`,
  });

  const {
    callApi: getTripleseatSelectedLocations,
    data: tripleseatSelectedLocations,
    isLoading: getTripleseatSelectedLocationsLoader,
  } = useGet({
    endpoint: `integration/tripleseat-selected-location`,
  });

  const {
    callApi: getMailChimpSelectedCampaigns,
    data: mailChimpSelectedCampaigns,
    isLoading: getMailChimpSelectedCampaignsLoader,
  } = useGet({
    endpoint: `integration/mailchimp-selected-campaign`,
  });

  const {
    callApi: saveLeadSource,
    isLoading: saveLoading,
    processSuccess,
  } = usePost({ endpoint: `leadsource` });

  const {
    callApi: getProviders,
    isLoading: getProvidersLoading,
    data: providers,
  } = useGet({
    endpoint: `leadsource/eventective/providers?email=${username}&password=${password}`,
  });

  const {
    callApi: getWeddingSpotVenues,
    isLoading: getWeddingSpotVenuesLoading,
    data: venues,
  } = useGet({
    endpoint: `leadsource/weddingspot/venues?email=${username}&password=${password}`,
  });

  const {
    callApi: getKnotStorefronts,
    isLoading: getKnotStorefrontsLoading,
    data: storefronts,
  } = useGet({
    endpoint: `leadsource/knot/storefronts?email=${username}&password=${encodedPassword}`,
  });
  const {
    callApi: getProvidersYelp,
    isLoading: getProvidersYelpLoading,
    data: providersYelp,
  } = useGet({
    endpoint: `leadsource/yelp/locations?email=${username}&password=${password}`,
  });

  const {
    callApi: getWebLinks,
    data: weblink,
    isLoading: getWebLinkLoader,
  } = useGet({
    endpoint: `weblink`,
  });
  const isLoading =
    getLeadSourcesOptionsLoader ||
    getDestinationsLoader ||
    getAutoRespondersLoader ||
    getAlertUsersLoader ||
    getTimezonesLoader ||
    getWebLinkLoader;

  useEffect(() => {
    if (id) getLeadSourceDetail();
  }, [id]);

  useEffect(() => {
    if (leadSourceDetail) {
      setLeadSourceSelected(leadSourceDetail.leadSource);
      setJobName(leadSourceDetail.jobName);
      setUsername(leadSourceDetail.username);
      setPassword(leadSourceDetail.password);
      setLeadSourceActivate(leadSourceDetail.active);
      setDestinationId(leadSourceDetail.destinations);
      setAutoResponderSelected(leadSourceDetail.autoresponderId);
      setAlertUserId(leadSourceDetail.alertId);
      setAlertSendType(leadSourceDetail.alertSendType);
      setSendAlertStartTime(leadSourceDetail.alertTime.startTime);
      setSendAlertEndTime(leadSourceDetail.alertTime.endTime);
      setTimezoneSelected(leadSourceDetail.alertTime.timezone);
      setExtraInfo(leadSourceDetail.extraInfo);
      setProvidersList(leadSourceDetail.extraInfo?.providersList);
      setLeadProvider(leadSourceDetail.extraInfo?.provider);
      setLocationIdTripleseat(leadSourceDetail.extraInfo?.locationIdTripleseat);
      setCampaignIdMailChimp(leadSourceDetail.extraInfo?.campaignIdMailChimp);
      if (leadSourceDetail.destinations.includes("tripleseat")) {
        setShowCardTripleseatLocation(true);
        getTripleseatSelectedLocations();
      }
      if (leadSourceDetail.destinations.includes("mailchimp")) {
        setShowCardMailChimpCampaigns(true);
        getMailChimpSelectedCampaigns();
      }
    }
  }, [leadSourceDetail]);

  // useEffect(() => {
  //     if (locationIdTripleseat && !tripleSeatLocationsLoaded) {
  //         getTripleseatSelectedLocations();
  //         setShowCardTripleseatLocation(true);
  //         setTripleSeatLocationsLoaded(true);
  //     }
  // }, [locationIdTripleseat])

  useEffect(() => {
    if (processSuccess) {
      clearForm();
      getLeadSources();
    }
    if (providers) setProvidersList(providers);
    else if (venues) setProvidersList(venues);
    else if (storefronts) setProvidersList(storefronts);
    else if (providersYelp) setProvidersList(providersYelp);
    else if (weblink && leadSourceSelected === SOURCES.LEAD_CONTACT_FORM)
      setProvidersList(weblink?.results);
  }, [processSuccess, providers, providersYelp, venues, storefronts, weblink]);

  useEffect(() => {
    if (timezones) {
      const tzList = [];
      timezones.map((tz) => {
        tzList.push({
          label: `${tz.text} ${tz.utc[0]}`,
          value: tz.utc[0],
        });
      });
      setTimezoneList(tzList);
    }
  }, [timezones]);

  useEffect(() => {
    if (alertSendType && alertSendType !== "none") setSendAlert(true);
    else setSendAlert(false);
  }, [alertSendType]);

  useEffect(() => {
    getLeadSourcesOptions();
    getDestinations();
    getAutoResponders();
    getAlertUsers();
    getTimezones();
  }, []);

  useEffect(() => {
    if (tripleSeatLocationsLoaded) {
      setShowCardTripleseatLocation(true);
      getTripleseatSelectedLocations();
    }
    if (mailChimpCampaignsLoaded) {
      setShowCardMailChimpCampaigns(true);
      getMailChimpSelectedCampaigns();
    }
  }, [tripleSeatLocationsLoaded, mailChimpCampaignsLoaded]);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          leadSourceSelected,
          jobName,
          password,
          username,
        },
        { abortEarly: false }
      ); //*/

      setErrors({});

      const postData = {
        id,
        leadSource: leadSourceSelected,
        jobName,
        username,
        password,
        active: leadSourceActivate,
        destinations: destinationId,
        autoresponderId:
          autoresponderSelected === "" ? null : autoresponderSelected,
        alertSendType,
        alertSendStartTime,
        alertSendEndTime,
        alertSendTimezone: timezoneSelected,
        alertId: alertUserId,
        extraInfo: {
          ...extraInfo,
          provider: leadProvider,
          providersList,
          locationIdTripleseat,
          campaignIdMailChimp,
        },
      };
      await saveLeadSource(postData, MSG_LEADSOURCE.SAVE);
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    }
  };

  const activate = (e) => {
    setLeadSourceActivate(e.target.checked);
  };

  const chooseScrappingLink = (e) => {
    let info = {
      eventectiveScrapeLeads: false,
      eventectiveScrapeReferral: false,
      providers: [],
      provider: leadProvider,
    };
    if (e.target.id === "eventectiveScrapeLeads")
      info.eventectiveScrapeLeads = true;
    else info.eventectiveScrapeReferral = true;

    setExtraInfo(info);
  };

  const clearForm = () => {
    setId("");
    setLeadSourceSelected("");
    setJobName("");
    setUsername("");
    setPassword("");
    setLeadSourceActivate(false);
    setDestinationId([]);
    setShowForm(false);
  };

  const handleDestinations = (e) => {
    const isTripleSeatDestination =
      e.target.id === SOURCES.DESTINATION_TRIPPLE_SEAT;

    const isMailChimpDestination =
      e.target.id === SOURCES.DESTINATION_MAILCHIMP;

    if (
      e.target.checked &&
      !tripleSeatLocationsLoaded &&
      isTripleSeatDestination
    )
      setTripleSeatLocationsLoaded(true);

    if (e.target.checked && !mailChimpCampaignsLoaded && isMailChimpDestination)
      setMailChimpCampaignsLoaded(true);

    if (isTripleSeatDestination)
      setShowCardTripleseatLocation(!showCardTripleseatLocation);

    if (isMailChimpDestination)
      setShowCardMailChimpCampaigns(!showCardMailChimpCampaigns);

    if (e.target.checked) setDestinationId([...destinationId, e.target.id]);
    else {

      setDestinationId(destinationId.filter(
        (dec) => dec !== e.target.id));
    }
  };

  const handleAlertUser = (e) => {
    if (e.target.checked) setAlertUserId([...alertUserId, e.target.id]);
    else {
      const temparr = alertUserId.filter((alt) => alt !== e.target.id);
      setAlertUserId(temparr);
    }
  };

  const hidePopup = () => {
    setToggleAutoresponderModal(false);
    setToggleRecipientModal(false);
    getAlertUsers();
    getAutoResponders();
  };

  useKey(() => {
    setToggleRecipientModal(false);
    setToggleAutoresponderModal(false);
    if (!toggleRecipientModal) clearForm();
  }, "Escape");

  useKey(() => submitForm(), "Enter");

  useEffect(() => {
    if (leadSourceSelected === SOURCES.LEAD_CONTACT_FORM) {
      setUsername("-");
      setPassword("-");
      getWebLinks();
    }
  }, [leadSourceSelected]);

  return (
    <Fade>
      <Row>
        <Col sm="12" className="mb-2">
          <Card color="shadow" className="shadow">
            {isLoading ? (
              <center className="p-2">
                <Spinner className="ml-2" children="" />
              </center>
            ) : (
              <CardBody>
                <CardTitle tag="h4">
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Create Import Job
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                ></CardSubtitle>
                <CardText>
                  <Form onSubmit={submitForm} autoComplete="off">
                    <GenericTabs
                      tabs={[
                        {
                          tabId: "1",
                          title: "Credentials",
                          icon: "images/tabs/credentials.png",
                          className: "shadow",
                          component: (
                            <Card
                              className="shadow-sm"
                              style={{ marginTop: "10px" }}
                            >
                              <CardBody>
                                <Row>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label for="leadSource">
                                        Lead Source
                                      </Label>
                                      <Input
                                        id="leadSource"
                                        name="select"
                                        type="select"
                                        className="w-100 p-2 "
                                        onChange={(e) => {
                                          setLeadSourceSelected(e.target.value);
                                        }}
                                        invalid={errors.leadSourceSelected}
                                        tabIndex={1}
                                        autoFocus
                                      >
                                        <option value="">
                                          Select lead source
                                        </option>
                                        {leadSource &&
                                          leadSource.map((opt) => {
                                            return (
                                              <option
                                                value={opt.key}
                                                selected={
                                                  opt.key === leadSourceSelected
                                                }
                                              >
                                                {opt.title}
                                              </option>
                                            );
                                          })}
                                      </Input>
                                      <ValidationErrorHandling
                                        error={errors.leadSourceSelected}
                                      />
                                    </FormGroup>
                                    {leadSourceSelected !==
                                      SOURCES.LEAD_CONTACT_FORM && (
                                      <FormGroup>
                                        <Label for="username">
                                          Username/Email
                                        </Label>
                                        <Input
                                          id="username"
                                          name="username"
                                          placeholder="Username/Email"
                                          type="text"
                                          value={username}
                                          onChange={(e) => {
                                            setUsername(e.target.value);
                                          }}
                                          invalid={errors.username}
                                          tabIndex={3}
                                          autoComplete="off"
                                        />
                                        <ValidationErrorHandling
                                          error={errors.username}
                                        />
                                      </FormGroup>
                                    )}
                                  </Col>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label for="jobName">Job Name</Label>
                                      <Input
                                        id="jobName"
                                        name="jobname"
                                        placeholder="Job name"
                                        type="text"
                                        onChange={(e) => {
                                          setJobName(e.target.value);
                                        }}
                                        invalid={errors.jobName}
                                        value={jobName}
                                        tabIndex={2}
                                      />
                                      <ValidationErrorHandling
                                        error={errors.jobName}
                                      />
                                    </FormGroup>
                                    {leadSourceSelected !==
                                      SOURCES.LEAD_CONTACT_FORM && (
                                      <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Input
                                          id="password"
                                          name="password"
                                          placeholder="Password"
                                          type="password"
                                          value={password}
                                          onChange={(e) => {
                                            setPassword(e.target.value);
                                          }}
                                          invalid={
                                            leadSourceSelected !==
                                              SOURCES.LEAD_CONTACT_FORM &&
                                            errors.password
                                          }
                                          tabIndex={4}
                                          autoComplete="new-password"
                                        />
                                        <ValidationErrorHandling
                                          error={
                                            leadSourceSelected !==
                                              SOURCES.LEAD_CONTACT_FORM &&
                                            errors.password
                                          }
                                        />
                                      </FormGroup>
                                    )}
                                  </Col>
                                </Row>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    id="leadSourceActivate"
                                    onChange={activate}
                                    checked={leadSourceActivate}
                                    tabIndex={5}
                                  />{" "}
                                  <Label check for={"leadSourceActivate"}>
                                    {" "}
                                    Activate Job
                                  </Label>
                                </FormGroup>

                                {leadSourceSelected &&
                                  leadSourceSelected ===
                                    SOURCES.LEAD_CONTACT_FORM && (
                                    <div className="p-3 my-3 border rounded shadow">
                                      <FormGroup>
                                        <legend>
                                          <h5>Choose Lead Contact Form </h5>
                                        </legend>
                                      </FormGroup>

                                      <FormGroup check className="pl-0">
                                        <div>
                                          <Input
                                            id="leadProviders"
                                            name="select"
                                            type="select"
                                            className="w-40 p-2 mr-2"
                                            onChange={(e) => {
                                              setLeadProvider(e.target.value);
                                            }}
                                            // invalid={errors.leadProvider}
                                          >
                                            <option value="">Select one</option>
                                            {providersList &&
                                              providersList.map((lcf) => {
                                                return (
                                                  <option
                                                    value={lcf._id}
                                                    selected={
                                                      extraInfo &&
                                                      lcf._id ===
                                                        extraInfo.provider
                                                    }
                                                  >
                                                    {lcf.formName}
                                                  </option>
                                                );
                                              })}
                                          </Input>
                                          {/* <ValidationErrorHandling error={errors.leadProvider} /> */}
                                        </div>
                                      </FormGroup>
                                    </div>
                                  )}

                                {leadSourceSelected &&
                                  leadSourceSelected ===
                                    SOURCES.LEAD_EVENTECTIVE && (
                                    <div className="p-3 my-3 border rounded shadow">
                                      <FormGroup>
                                        <legend>
                                          <h5>Choose Type </h5>
                                        </legend>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                          type="radio"
                                          id="eventectiveScrapeLeads"
                                          name="extraInfo"
                                          onChange={chooseScrappingLink}
                                          checked={
                                            extraInfo?.eventectiveScrapeLeads
                                          }
                                          tabIndex={6}
                                        />
                                        <Label for="eventectiveScrapeLeads">
                                          Leads
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                          type="radio"
                                          id="eventectiveScrapeReferral"
                                          name="extraInfo"
                                          onChange={chooseScrappingLink}
                                          checked={
                                            extraInfo?.eventectiveScrapeReferral
                                          }
                                          tabIndex={7}
                                        />
                                        <Label for="eventectiveScrapeReferral">
                                          {" "}
                                          Referrals
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check className="pl-0">
                                        <Row>
                                          <Col
                                            lg="auto"
                                            md="auto"
                                            style={{ paddingRight: "5px" }}
                                          >
                                            <Input
                                              id="leadProviders"
                                              name="select"
                                              type="select"
                                              className="w-100 p-2 mr-2 mb-2"
                                              onChange={(e) => {
                                                setLeadProvider(e.target.value);
                                              }}
                                              // invalid={errors.leadProvider}
                                              tabIndex={8}
                                            >
                                              <option value="">
                                                Select lead provider
                                              </option>
                                              {providersList &&
                                                providersList?.map((opt) => {
                                                  return (
                                                    <option
                                                      value={opt.ProviderNum}
                                                      selected={
                                                        extraInfo &&
                                                        opt?.ProviderNum ===
                                                          parseInt(
                                                            extraInfo?.provider
                                                          )
                                                      }
                                                    >
                                                      {opt.ProviderNameFull}
                                                    </option>
                                                  );
                                                })}
                                            </Input>
                                            {/* <ValidationErrorHandling error={errors.leadProvider} /> */}
                                          </Col>
                                          <Col lg="auto" md="auto">
                                            <Button
                                              className="btn btn-info"
                                              onClick={() => getProviders()}
                                              disabled={getProvidersLoading}
                                            >
                                              Get Providers
                                              {getProvidersLoading && (
                                                <Spinner
                                                  className="ml-2"
                                                  color="light"
                                                  size="sm"
                                                  children=""
                                                />
                                              )}
                                            </Button>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </div>
                                  )}

                                {leadSourceSelected &&
                                  leadSourceSelected ===
                                    SOURCES.LEAD_WEDDINGSPOT && (
                                    <div className="p-3 my-3 border rounded shadow">
                                      <FormGroup check className="pl-0">
                                        <Row>
                                          <Col
                                            lg="auto"
                                            md="auto"
                                            className="pb-2"
                                          >
                                            <Input
                                              id="leadProviders"
                                              name="select"
                                              type="select"
                                              className="w-100 p-2 mr-2 mb-2"
                                              onChange={(e) =>
                                                setLeadProvider(e.target.value)
                                              }
                                              tabIndex={8}
                                            >
                                              <option value="">
                                                Select venue
                                              </option>
                                              {providersList &&
                                                providersList.map((opt) => {
                                                  return (
                                                    <option
                                                      value={opt.id}
                                                      selected={
                                                        extraInfo &&
                                                        parseInt(opt.id) ===
                                                          parseInt(
                                                            extraInfo.provider
                                                          )
                                                      }
                                                    >
                                                      {opt.name}
                                                    </option>
                                                  );
                                                })}
                                            </Input>
                                            {/* <ValidationErrorHandling error={errors.leadProvider} /> */}
                                          </Col>
                                          <Col lg="auto" md="auto">
                                            <Button
                                              className="btn btn-info"
                                              onClick={() =>
                                                getWeddingSpotVenues()
                                              }
                                              disabled={
                                                getWeddingSpotVenuesLoading
                                              }
                                            >
                                              Get Venues
                                              {getWeddingSpotVenuesLoading && (
                                                <Spinner
                                                  className="ml-2"
                                                  color="light"
                                                  size="sm"
                                                  children=""
                                                />
                                              )}
                                            </Button>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </div>
                                  )}

                                {leadSourceSelected &&
                                  leadSourceSelected === SOURCES.LEAD_KNOT && (
                                    <div className="p-3 my-3 border rounded shadow">
                                      <FormGroup>
                                        <Row>
                                          <Col
                                            lg="auto"
                                            md="auto"
                                            className="pb-2"
                                          >
                                            {/* <Col style={{ width: "40%" }}> */}
                                            <Input
                                              id="leadProviders"
                                              name="select"
                                              type="select"
                                              className="w-100 p-2 mr-2"
                                              onChange={(e) =>
                                                setLeadProvider(e.target.value)
                                              }
                                              // invalid={errors.leadProvider}
                                            >
                                              <option value="">
                                                Select storefront
                                              </option>
                                              {providersList &&
                                                providersList.map((opt) => {
                                                  return (
                                                    <option
                                                      value={opt.id}
                                                      selected={
                                                        extraInfo &&
                                                        opt.id ===
                                                          extraInfo.provider
                                                      }
                                                    >
                                                      {opt.name}
                                                    </option>
                                                  );
                                                })}
                                            </Input>
                                            {/* <ValidationErrorHandling error={errors.leadProvider} /> */}
                                          </Col>
                                          <Col lg="auto" md="auto">
                                            <Button
                                              className="btn btn-info"
                                              onClick={() =>
                                                getKnotStorefronts()
                                              }
                                              disabled={
                                                getKnotStorefrontsLoading
                                              }
                                            >
                                              Get Storefronts
                                              {getKnotStorefrontsLoading && (
                                                <Spinner
                                                  className="ml-2"
                                                  color="light"
                                                  size="sm"
                                                  children=""
                                                />
                                              )}
                                            </Button>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </div>
                                  )}
                              </CardBody>
                            </Card>
                          ),
                        },
                        {
                          tabId: "2",
                          title: "Leads Destination(s)",
                          icon: "images/tabs/destination.png",

                          component: (
                            <>
                              <Card
                                className="shadow"
                                style={{ marginTop: "10px" }}
                              >
                                <CardBody>
                                  <Row>
                                    <Col sm={6}>
                                      <FormGroup>
                                        <legend>
                                          <h5>Leads Destinations</h5>
                                        </legend>
                                        {destinations &&
                                          destinations.map((des, idx) => {
                                            return (
                                              <FormGroup check>
                                                <Input
                                                  type="checkbox"
                                                  id={des.integrationCompany}
                                                  onChange={handleDestinations}
                                                  checked={destinationId.includes(
                                                    des.integrationCompany.toString()
                                                  )}
                                                  tabIndex={6}
                                                />
                                                <Label
                                                  check
                                                  for={des.integrationCompany}
                                                >
                                                  {capitalizeFirstLetter(
                                                    des.integrationCompany
                                                  )}
                                                </Label>
                                              </FormGroup>
                                            );
                                          })}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              {showCardTripleseatLocation && (
                                <TripleseatLocationModule
                                  getTripleseatSelectedLocationsLoader={
                                    getTripleseatSelectedLocationsLoader
                                  }
                                  setLocationIdTripleseat={
                                    setLocationIdTripleseat
                                  }
                                  tripleseatSelectedLocations={
                                    tripleseatSelectedLocations
                                  }
                                  extraInfo={extraInfo}
                                />

                                /*
                                <Fade>
                                  <Card
                                    className="shadow-sm"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <CardBody>
                                      <Row>
                                        <Col sm={6} md={6} lg={6}>
                                          <FormGroup>
                                            <legend>
                                              <h5>
                                                Choose Tripleseat Location to
                                                Send Leads
                                              </h5>
                                            </legend>

                                            {getTripleseatSelectedLocationsLoader ? (
                                              <Spinner
                                                className="ml-2"
                                                children=""
                                              />
                                            ) : (
                                              <Input
                                                id="tripleseatLocation"
                                                name="select"
                                                type="select"
                                                className="w-100 p-2 mr-2"
                                                onChange={(e) =>
                                                  setLocationIdTripleseat(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Select tripleseat location
                                                </option>
                                                {tripleseatSelectedLocations &&
                                                  tripleseatSelectedLocations.map(
                                                    (opt) => {
                                                      return (
                                                        <option
                                                          value={opt.id}
                                                          selected={
                                                            extraInfo?.locationIdTripleseat &&
                                                            opt?.id.toString() ===
                                                              extraInfo?.locationIdTripleseat.toString()
                                                          }
                                                        >
                                                          {opt.name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </Input>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Fade>
                              */
                              )}
                              {showCardMailChimpCampaigns && (
                                <MailChimpCampaignsModules
                                  getMailChimpSelectedCampaignsLoader={
                                    getMailChimpSelectedCampaignsLoader
                                  }
                                  setCampaignIdMailChimp={
                                    setCampaignIdMailChimp
                                  }
                                  mailChimpSelectedCampaigns={
                                    mailChimpSelectedCampaigns
                                  }
                                  extraInfo={extraInfo}
                                />
                              )}
                            </>
                          ),
                        },
                        {
                          tabId: "3",
                          title: "Alerts",
                          icon: "images/tabs/alert.png",
                          className: "shadow",
                          component: (
                            <Card
                              className="shadow-sm"
                              style={{ marginTop: "10px" }}
                            >
                              <CardBody>
                                <Row>
                                  <Col sm={6} lg={12}>
                                    <legend>
                                      <h5>Alerts</h5>
                                    </legend>
                                    <Row>
                                      <Col sm={6}>
                                        <FormGroup>
                                          <Label for="autoresponder">
                                            Do you want to send email/sms alerts
                                            or create new recipient?{" "}
                                            <div
                                              className="text-primary"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                setToggleRecipientModal(true)
                                              }
                                            >
                                              New Recipient
                                            </div>
                                          </Label>
                                          {toggleRecipientModal && (
                                            <ModalDialog
                                              title="Create Recipient"
                                              showPopup={toggleRecipientModal}
                                              hidePopup={() =>
                                                setToggleRecipientModal(false)
                                              }
                                            >
                                              <AlertEmailSms
                                                showFormInitial={true}
                                                parentMethod={() => hidePopup()}
                                              />
                                            </ModalDialog>
                                          )}
                                          <Input
                                            id="alertSendType"
                                            name="alertSendType"
                                            type="select"
                                            className="w-100 p-2 "
                                            onChange={(e) => {
                                              setSendAlert(
                                                e.target.value ? true : false
                                              );
                                              setAlertSendType(e.target.value);
                                            }}
                                            tabIndex={7}
                                          >
                                            <option value="">
                                              Select alert send time
                                            </option>
                                            <option
                                              value="anytime"
                                              selected={
                                                alertSendType === "anytime"
                                              }
                                            >
                                              Anytime
                                            </option>
                                            <option
                                              value="specifictime"
                                              selected={
                                                alertSendType === "specifictime"
                                              }
                                            >
                                              Specific Time
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    {sendAlert && (
                                      <>
                                        <Fade>
                                          {alertSendType === "specifictime" && (
                                            <Row>
                                              <Col sm={6}>
                                                <FormGroup>
                                                  <Label for="startTime">
                                                    Start Time
                                                  </Label>
                                                  <TimePicker
                                                    className="w-100"
                                                    id="startTime"
                                                    format="HH:mm"
                                                    value={alertSendStartTime}
                                                    onChange={
                                                      setSendAlertStartTime
                                                    }
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col sm={6}>
                                                <FormGroup>
                                                  <Label for="endTime">
                                                    End Time
                                                  </Label>
                                                  <TimePicker
                                                    className="w-100"
                                                    id="endTime"
                                                    format="HH:mm"
                                                    value={alertSendEndTime}
                                                    onChange={
                                                      setSendAlertEndTime
                                                    }
                                                  />
                                                </FormGroup>
                                              </Col>

                                              <Col sm={12}>
                                                <FormGroup>
                                                  <Label for="timezone">
                                                    Timezone
                                                  </Label>

                                                  {timezoneList && (
                                                    <Select
                                                      isLoading={false}
                                                      options={timezoneList}
                                                      onChange={(opt) => {
                                                        setTimezoneSelected(
                                                          opt.value
                                                        );
                                                      }}
                                                      value={timezoneList.filter(
                                                        function (option) {
                                                          return (
                                                            option.value ===
                                                            timezoneSelected
                                                          );
                                                        }
                                                      )}
                                                    />
                                                  )}
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          )}
                                          <Row>
                                            <Col sm={12}>
                                              <FormGroup>
                                                <Label for="autoresponder">
                                                  Choose from below users if you
                                                  would like to send alerts?
                                                </Label>
                                                <Table
                                                  responsive
                                                  striped
                                                  bordered
                                                  hover
                                                  size="sm"
                                                  className="shadow"
                                                >
                                                  <TableHeader
                                                    headers={[
                                                      { title: "" },
                                                      { title: "Name" },
                                                      { title: "Email" },
                                                      { title: "Mobile No" },
                                                      { title: "Alert Via" },
                                                    ]}
                                                  />
                                                  <tbody>
                                                    {alertUsers &&
                                                      alertUsers.map(
                                                        (res, idx) => {
                                                          return (
                                                            <tr
                                                              style={{
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                            >
                                                              <td>
                                                                <Input
                                                                  style={{
                                                                    marginLeft: 0,
                                                                    position:
                                                                      "static",
                                                                  }}
                                                                  checked={alertUserId.includes(
                                                                    res._id.toString()
                                                                  )}
                                                                  type="checkbox"
                                                                  id={res._id}
                                                                  onChange={
                                                                    handleAlertUser
                                                                  }
                                                                />
                                                              </td>
                                                              <td>
                                                                {res.alertName}
                                                              </td>
                                                              <td>
                                                                {res.alertEmail}
                                                              </td>
                                                              <td>
                                                                {res.alertSms}
                                                              </td>
                                                              <td>
                                                                {res.alertVia ===
                                                                SOURCES.ALERT_VIA_BOTH
                                                                  ? capitalizeFirstLetter(
                                                                      SOURCES.ALERT_VIA_EMAIL
                                                                    ) +
                                                                    " / " +
                                                                    capitalizeFirstLetter(
                                                                      SOURCES.ALERT_VIA_SMS
                                                                    )
                                                                  : capitalizeFirstLetter(
                                                                      res.alertVia
                                                                    )}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                  </tbody>
                                                </Table>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </Fade>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          ),
                        },
                      ]}
                    />

                    <CardFooter>
                      <FormGroup
                        style={{
                          display: "flex",
                          gap: "5px",
                          flexWrap: "wrap",
                        }}
                      >
                        <SubmitButton
                          isLoading={saveLoading}
                          cancleButtonOnClick={() => {
                            setShowForm(false);
                            clearForm();
                          }}
                        />
                        {saveLoading && (
                          <div className="alert alert-primary mt-2 my-0 py-1">
                            Validating lead source credentials
                          </div>
                        )}
                      </FormGroup>
                    </CardFooter>
                  </Form>
                </CardText>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Fade>
  );
};

export default LeadSourcesForm;
