import React, { useEffect, useState } from 'react'
import moment from 'moment';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CardFooter,
  Fade,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faSave } from "@fortawesome/free-solid-svg-icons";
import usePost from '../../../services/usePost';
import SOURCES from '../../../utils/constants';
import { useKey } from '../../../services/useKey';
import { MSG_COUPONS } from '../../../utils/message';
import ValidationErrorHandling from '../../common/validationErrorHandling';
import { handleYupValidationErrors, yupValidationSchema } from '../../../utils/functions';
import SubmitButton from '../../common/submitButton';
import useGet from '../../../services/useGet';

const validationSchema = yupValidationSchema({
  code: { required: true, customName: "Code" },
  couponType: { required: true, customName: "Coupon type" },
  amount: { positive: true, required: true, customName: "Amount" },
  expiryDate: { date: true, required: true, customName: "Expiry date" },
  usageAllowed: { required: true, customName: "Usage allowed" },
});


const CouponsForm = ({ id = null, setId, getCoupons, setShowForm, parentMethod, coupon }) => {
  const { DISCOUNT_FIXED, DISCOUNT_PERCENTAGE, DISCOUNT_TRIAL } = SOURCES;
  const [code, setCode] = useState("");
  const [couponType, setCouponType] = useState("");
  const [amount, setAmount] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [usageAllowed, setUsageAllowed] = useState(0);
  const [errors, setErrors] = useState('')
  const [paymentPlanDescription, setPaymentPlanDescription] = useState("");
  const [paymentPlanId, setPaymentPlanId] = useState(null);
  const [paymentStripePlanId, setPaymentStripePlanId] = useState("-1");
  const [paymentPlanCode, setPaymentPlanCode] = useState("");

  const {
    callApi: saveCoupon,
    isLoading,
    processSuccess,
  } = usePost({ endpoint: `coupon` });


  useEffect(() => {
    if (couponType === DISCOUNT_TRIAL) setAmount(0);
  }, [couponType])

  useEffect(() => {
    if (coupon && id) {
      if (parentMethod) parentMethod();
      else {
        setCode(coupon.code);
        setCouponType(coupon.couponType);
        setAmount(coupon.amount);
        // setExpiryDate(moment(coupon.expiryDate).format("MM/DD/YYYY"));
        setExpiryDate(moment(coupon.expiryDate).format("YYYY-MM-DD"));
        setUsageAllowed(coupon.usageAllowed);
        setPaymentPlanId(coupon?.upgradeToPlanId);
        setShowForm(true);

      }
    }
  }, [coupon, parentMethod]);

  useEffect(() => {
    if (processSuccess) {
      if (parentMethod) parentMethod();
      else {
        clearForm();
        getCoupons();
      }
    }
  }, [processSuccess]);

  useKey(() => {
    setShowForm(false);
    clearForm();
  }, "Escape")

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          code,
          couponType,
          amount,
          expiryDate,
          usageAllowed,
        },
        { abortEarly: false }
      );
      const postData = {
        id,
        code,
        couponType,
        amount,
        expiryDate,
        usageAllowed: usageAllowed === "" ? 0 : usageAllowed,
        paymentPlanId 
      };
      saveCoupon(postData, MSG_COUPONS.SAVE);

      setErrors({});
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
    }

  };

  const clearForm = () => {
    if (parentMethod) parentMethod();
    else {
      setId("");
      setCode("");
      setCouponType("");
      setAmount("");
      setExpiryDate("");
      setShowForm(false);
      
    }
  };
  // my work
  const queryParams = new URLSearchParams(window.location.search);

  const plan = queryParams.get("plan");

  const { callApi: getPaymentPlans, data: paymentPlans } = useGet({
    endpoint: `payment/plans`,
  });

  const getPaymentPlanDetail = async (planId) => {
    setPaymentPlanId(planId);
    setPaymentPlanDescription("");
    setAmount(0); //If COUPON use to give customer next Plan then no need to create this plan on STRIPE

    paymentPlans && paymentPlans.map((plan) => {
      if (planId === plan._id) {
        setPaymentStripePlanId(plan.stripePlanId);
        setPaymentPlanDescription(plan.planDetail);
        setPaymentPlanCode(plan.planCode);

      }
    });
  };

  useEffect(() => {
    getPaymentPlans();
  }, []);

  useEffect(() => {
    if (plan) getPaymentPlanDetail(plan);
  }, [plan, paymentPlans]);


  const formatExpiryDate = (e)=>{
    const inputDate = e.target.value;

    // Split the date into year, month, and day
    const [year, month, day] = inputDate.split('-');

    // Format the date as "mm/dd/yyyy"
    const formattedDate = `${month}/${day}/${year}`;
    console.log(inputDate,formattedDate);

    // Set the formatted date in your state or display it in another element
    setExpiryDate(formattedDate);
  }
  return (
    <Fade>
      <Row>
        <Col sm="12" className="mb-2">
          <Card color="shadow">
            <CardBody>
              <CardTitle tag="h4">
                <FontAwesomeIcon icon={faCoins} className="mr-2" />
                Coupon
              </CardTitle>
              <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
              ></CardSubtitle>
              <CardText>
                <Form onSubmit={submitForm}>
                  <div className='border shadow-sm  rounded-lg p-4 my-3'>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Coupon Code</Label>
                          <Input
                            id="code"
                            name="code"
                            type="text"
                            placeholder="Enter coupon code"
                            className="w-100 p-2 "
                            onChange={(e) => {
                              setCode(e.target.value.toUpperCase().trim());
                            }}
                            invalid={errors.code}
                            value={code}
                            tabIndex={1}
                            autoFocus
                          />
                          <ValidationErrorHandling error={errors.code} />
                        </FormGroup>
                      </Col>

                      <Col sm={6}>
                        <FormGroup>
                          <Label for="couponType">Coupon Type</Label>
                          <Input
                            id="couponType"
                            name="select"
                            type="select"
                            className="w-100 p-2 "
                            onChange={(e) => {
                              setCouponType(e.target.value);
                            }}
                            invalid={errors.couponType}
                            tabIndex={2}
                          >
                            <option value="">Select coupon type</option>

                            <option
                              value={DISCOUNT_FIXED}
                              selected={DISCOUNT_FIXED === couponType}
                            >
                              Fixed
                            </option>
                            <option
                              value={DISCOUNT_PERCENTAGE}
                              selected={DISCOUNT_PERCENTAGE === couponType}
                            >
                              Percentage
                            </option>
                            <option
                              value={DISCOUNT_TRIAL}
                              selected={DISCOUNT_TRIAL === couponType}
                            >
                              Free Trial
                            </option>
                          </Input>
                          <ValidationErrorHandling error={errors.couponType} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Amount</Label>
                          <Input
                            id="amount"
                            name="amount"
                            placeholder="Amount"
                            type="number"
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                            invalid={errors.amount}
                            value={amount}
                            tabIndex={3}
                          />
                          <ValidationErrorHandling error={errors.amount} />
                        </FormGroup>
                      </Col>

                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Expiry Date</Label>
                          <Input
                            id="date"
                            name="date"
                            type="date"
                            onChange={(e) => {setExpiryDate(e.target.value);}}
                            // onChange={formatExpiryDate}
                            invalid={errors.expiryDate}
                            value={expiryDate}
                            tabIndex={4}
                          />
                          <ValidationErrorHandling error={errors.expiryDate} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Total Usage Allowed <small><i>(Leave blank for unlimited usage)</i></small></Label>

                          <Input
                            id="usageAllowed"
                            name="usageAllowed"
                            placeholder="Usage allowed"
                            type="number"
                            step="0.01"
                            onChange={(e) => {
                              setUsageAllowed(e.target.value);
                            }}
                            invalid={errors.usageAllowed}
                            value={usageAllowed}
                            tabIndex={5}
                          />
                          <ValidationErrorHandling error={errors.usageAllowed} />
                        </FormGroup>
                      </Col>
                      {/* my work*/}
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Upgrade To Plan</Label>
                          <Input
                            bsSize='sm'
                            name="Plan"
                            // className="form-control form-control-lg"
                            className="w-100 p-2 "
                            type="select"
                            value={paymentPlanId}
                            invalid={errors.paymentPlanId}
                            onChange={(e) => {
                              getPaymentPlanDetail(e.target.value);
                            }}
                            autoComplete="off"
                          >
                            <option value="-1">Choose plan</option>
                            {paymentPlans &&
                              paymentPlans.map((plan) => {
                                return (
                                  <option value={plan._id} selected={plan._id === paymentPlanId} >{plan.planName}</option>
                                );
                              })}
                          </Input>
                          <ValidationErrorHandling error={errors.paymentPlanId} />
                        </FormGroup>
                        {/* <FormGroup>{paymentPlanDescription}</FormGroup> */}

                        {/* {paymentPlanCode && paymentPlanCode !== "p1" && (
                          <FormGroup>
                            <Input
                              id="coupon"
                              name="coupon"
                              placeholder="Enter coupon If any..."
                              className="form-control form-control-lg"
                              type="text"
                              value={coupon}
                              onChange={(e) => {
                                setCoupon(e.target.value);
                              }}
                              autoComplete="off"
                            />
                          </FormGroup>

                        )} */}
                      </Col>
                      {/* my work */}
                    </Row>
                  </div>
                  <CardFooter>
                    <FormGroup>
                      <SubmitButton
                        type="submit"
                        color="primary"
                        cancleButtonOnClick={() => {
                          setShowForm(false);
                          clearForm();
                        }}
                        isLoading={isLoading}
                        title="Save Changes"
                        icon={faSave}
                      />
                      {/* <Button
                      type="submit"
                      color="primary"
                      onClick={submitForm}
                    >
                       <FontAwesomeIcon icon={faSave} className="mr-2" />
                      Save Changes
                      {isLoading && (
                        <Spinner
                          className="ml-2"
                          color="light"
                          size="sm"
                          children=""
                        />
                      )}
                    </Button>
                    <Button
                      color="secondary"
                      className="ml-2"
                      onClick={() => {
                        setShowForm(false);
                        clearForm();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Cancel
                    </Button> */}
                    </FormGroup>
                  </CardFooter>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fade>
  )
}

export default CouponsForm