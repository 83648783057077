import { useEffect, useState, useRef } from "react";
import TimePicker from "react-time-picker";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CardFooter,
  InputGroup,
  Fade,
} from "reactstrap";
import usePost from "../../services/usePost";
import useGet from "../../services/useGet";
import { useKey } from "../../services/useKey";
import { MSG_AUTORESPONDER } from "../../utils/message";
import GenericTabs from "../common/tab";
import ValidationErrorHandling from "../common/validationErrorHandling";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../utils/functions";
import SubmitButton from "../common/submitButton";

const validationSchema = yupValidationSchema({
  senderEmail: { email: true, required: true, customName: "Sender email" },
  senderName: { required: true, customName: "Sender name" },
  autoresponderTitle: { required: true, customName: "Autoresponder title" },
  autoresponderName: { required: true, customName: "Autoresponder name" },
  timezoneSelected: { required: true, customName: "Timezone" },
  autoResponder: { required: true, customName: "Message" },
});

const AutoresponderForm = ({
  setShowForm,
  setId,
  getAutoResponders,
  showFormInitial,
  recordId = null,
  parentMethod = null,
  doCloneAutoresponder,
}) => {
  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 400,
    // textIcons: true,
    // toolbarButtonSize:"small",
    uploader: {
      insertImageAsBase64URI: true,
    },
    extraButtons: [
      //...Jodit.defaultOptions.buttons,
      {
        name: "First Name",
        tooltip: "Lead First Name",
        exec: (editor) => editor.s.insertHTML("[FIRST_NAME]"),
      },
      {
        name: "Event Type",
        tooltip: "Event Type",
        exec: (editor) => editor.s.insertHTML("[EVENT_TYPE]"),
      },
      {
        name: "Event Date",
        tooltip: "Event Date",
        exec: (editor) => editor.s.insertHTML("[EVENT_DATE]"),
      },
    ],
  };
  const [activeTabindex, setActiveTabindex] = useState(1);
  const [autoResponder, setAutoResponder] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [autoresponderTitle, setAutoresponderTitle] = useState("");
  const [autoresponderName, setAutoresponderName] = useState("");
  const [timezoneList, setTimezoneList] = useState([]);
  const [timezoneSelected, setTimezoneSelected] = useState(null);
  const [leadSourceId, setLeadSourceId] = useState([]);
  const [spcificDays, setSpcificDays] = useState(false);
  const [days, setDays] = useState();
  const [errors, setErrors] = useState({});

  const {
    callApi: saveAutoResponder,
    isLoading: saveLoading,
    processSuccess,
  } = usePost({ endpoint: `autoresponder` });

  const { callApi: getTimezones, data: timezones } = useGet({
    endpoint: `common/timezones`,
  });
  const { callApi: getLeadSourcesOptions, data: leadSources } = useGet({
    endpoint: `leadsource`,
  });

  const { callApi: getAutoResponder, data: autoresponder } = useGet({
    endpoint: `autoresponder`,
  });

  useEffect(() => {
    getTimezones();
    getLeadSourcesOptions();
    resetDays();
  }, []);

  useEffect(() => {
    if (autoresponder) {
      setSenderName(autoresponder?.senderName);
      setSenderEmail(autoresponder?.senderEmail);
      setAutoresponderTitle(autoresponder?.title);
      setAutoresponderName(autoresponder?.name);
      setAutoResponder(autoresponder?.message);
      setTimezoneSelected(autoresponder?.timezone);
      if (autoresponder?.autoresponderSentInfo) {
        if (autoresponder?.autoresponderSentInfo?.leadSources === null)
          setLeadSourceId([]);
        else setLeadSourceId(autoresponder?.autoresponderSentInfo?.leadSources);
        setSpcificDays(autoresponder?.autoresponderSentInfo?.sendSpecificDays);
        setDays(autoresponder?.autoresponderSentInfo?.sendDays);
      }
      setShowForm(true);
    }
  }, [autoresponder]);

  useEffect(() => {
    if (timezones) {
      const tzList = [];
      timezones.map((tz) => {
        tzList.push({
          label: `${tz.text} ${tz.utc[0]}`,
          value: tz.utc[0],
        });
      });
      setTimezoneList(tzList);
    }
  }, [timezones]);

  useEffect(() => {
    if (recordId) {
      getAutoResponder([{ key: "id", val: recordId }]);
    }
  }, [recordId]);

  useEffect(() => {
    if (processSuccess) {
      if (parentMethod) parentMethod();
      else {
        clearForm();
        getAutoResponders();
      }
    }
  }, [processSuccess]);

  const resetDays = () => {
    setDays([
      {
        name: "Monday",
        day: "Mon",
        active: false,
        startTime: "",
        endTime: "",
      },
      {
        name: "Tuesday",
        day: "Tue",
        active: false,
        startTime: "",
        endTime: "",
      },
      {
        name: "Wednesday",
        day: "Wed",
        active: false,
        startTime: "",
        endTime: "",
      },
      {
        name: "Thursday",
        day: "Thu",
        active: false,
        startTime: "",
        endTime: "",
      },
      {
        name: "Friday",
        day: "Fri",
        active: false,
        startTime: "",
        endTime: "",
      },
      {
        name: "Saturday",
        day: "Sat",
        active: false,
        startTime: "",
        endTime: "",
      },
      {
        name: "Sunday",
        day: "Sun",
        active: false,
        startTime: "",
        endTime: "",
      },
    ]);
  };

  const handleLeadSource = (e) => {
    if (e.target.checked) setLeadSourceId([...leadSourceId, e.target.id]);
    else {
      const temparr = leadSourceId.filter((dec) => dec !== e.target.id);
      setLeadSourceId(temparr);
    }
  };

  const handleTimeChange = (idx, field, value) => {
    const updateTime = [...days];
    updateTime[idx][field] = value;
    setDays(updateTime);
  };

  const handleUpdate = (newContent) => setAutoResponder(newContent);

  const handleSelectedDays = (idx, field, value) => {
    const updateTime = [...days];
    updateTime[idx][field] = value;
    setDays(updateTime);
  };

  const clearForm = () => {
    setId("");
    setAutoresponderTitle("");
    setAutoresponderName("");
    setAutoResponder("");
    setSenderName("");
    setSenderEmail("");
    setTimezoneSelected(null);
    setShowForm(false);
    setLeadSourceId([]);
    setSpcificDays(false);
    resetDays();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          senderName,
          senderEmail,
          autoresponderTitle,
          autoresponderName,
          timezoneSelected,
          autoResponder,
        },
        { abortEarly: false }
      );
      const postData = {
        id: recordId,
        title: autoresponderTitle,
        name: autoresponderName,
        message: autoResponder,
        timezone: timezoneSelected,
        senderName,
        senderEmail,
        autoresponderSentInfo: {
          leadSources: leadSourceId,
          sendSpecificDays: spcificDays,
          sendDays: days,
        },
      };
      await saveAutoResponder(postData, MSG_AUTORESPONDER.SAVE);
      setErrors({});
    } catch (e) {
      console.log("leadSourceId", leadSourceId);
      const errors = handleYupValidationErrors(e);
      setErrors(errors);
      if (
        errors?.autoresponderName ||
        errors?.autoresponderTitle ||
        errors?.senderEmail ||
        errors?.senderName
      )
        setActiveTabindex(1);
      else if (leadSourceId.length === 0) setActiveTabindex(2);
      else if (errors?.timezoneSelected) setActiveTabindex(3);
      else if (errors?.autoResponder) setActiveTabindex(4);
    }
  };

  useKey(() => {
    setShowForm(false);
    clearForm();
  }, "Escape");

  useKey(submitForm(), "Enter");

  return (
    <Fade>
      <Row>
        <Col sm="12" className="mb-2">
          <Card color="shadow">
            <CardBody>
              <CardTitle tag="h4">
                <FontAwesomeIcon icon={faReply} className="mr-2" />
                Autoresponder
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
              <CardText>
                <Form onSubmit={submitForm}>
                  <GenericTabs
                    tabs={[
                      {
                        tabId: "1",
                        title: "Basic",
                        icon: "images/tabs/basic.png",
                        className: "",
                        component: (
                          <div className="p-3 my-3 border rounded shadow">
                            <Row>
                              <Col sm={6}>
                                <FormGroup>
                                  <Label for="senderName">Sender Name</Label>
                                  <Input
                                    id="senderName"
                                    name="senderName"
                                    placeholder="Sender name"
                                    type="text"
                                    invalid={errors.senderName}
                                    value={senderName}
                                    onChange={(e) =>
                                      setSenderName(e.target.value)
                                    }
                                    autoComplete="off"
                                    autoFocus
                                  />
                                  <ValidationErrorHandling
                                    error={errors.senderName}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6}>
                                <FormGroup>
                                  <Label for="senderEmail">Sender Email</Label>
                                  <Input
                                    id="senderEmail"
                                    name="senderEmail"
                                    placeholder="Sender email"
                                    type="text"
                                    invalid={errors.senderEmail}
                                    value={senderEmail}
                                    onChange={(e) =>
                                      setSenderEmail(e.target.value)
                                    }
                                    autoComplete="off"
                                  />
                                  <ValidationErrorHandling
                                    error={errors.senderEmail}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <FormGroup>
                                  <Label for="title">
                                    Autoresponder Subject Title
                                  </Label>
                                  <Input
                                    id="title"
                                    name="title"
                                    placeholder="Autoresponder title"
                                    type="text"
                                    invalid={errors.autoresponderTitle}
                                    value={autoresponderTitle}
                                    onChange={(e) =>
                                      setAutoresponderTitle(e.target.value)
                                    }
                                    autoComplete="off"
                                  />
                                  <ValidationErrorHandling
                                    error={errors.autoresponderTitle}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <Label for="autoresponderName">
                                    Internal Autoresponder Name
                                  </Label>
                                  <Input
                                    id="autoresponderName"
                                    name="autoresponderName"
                                    placeholder="Autoresponder name"
                                    type="text"
                                    invalid={errors.autoresponderName}
                                    value={autoresponderName}
                                    onChange={(e) =>
                                      setAutoresponderName(e.target.value)
                                    }
                                    autoComplete="off"
                                  />
                                  <ValidationErrorHandling
                                    error={errors.autoresponderName}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        ),
                      },
                      {
                        tabId: "2",
                        title: "Leads Source",
                        icon: "images/tabs/leadsource.png",
                        className: "",
                        component: (
                          <div className="rounded border shadow p-3 my-3 ">
                            <Row>
                              <Col sm={12}>
                                <FormGroup>
                                  {leadSources &&
                                    leadSources.map((job, idx) => {
                                      return (
                                        <FormGroup check>
                                          <InputGroup>
                                            <Input
                                              type="switch"
                                              id={job._id}
                                              onChange={handleLeadSource}
                                              checked={leadSourceId?.includes(
                                                job._id
                                              )}
                                            />
                                            <Label check for={job._id}>
                                              {job.jobName}
                                            </Label>
                                          </InputGroup>
                                        </FormGroup>
                                      );
                                    })}
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        ),
                      },
                      {
                        tabId: "3",
                        title: "Alert",
                        icon: "images/tabs/alert.png",
                        className: "",
                        component: (
                          <div className="p-3 my-3 border rounded shadow">
                            <Row>
                              <Col sm={12}>
                                <FormGroup>
                                  <Label for="timezone">Timezone</Label>
                                  {timezoneList && (
                                    <Select
                                      id="timezone"
                                      name="timezone"
                                      className={
                                        errors.timezoneSelected
                                          ? "is-invalid"
                                          : ""
                                      }
                                      timezonesLoading={false}
                                      options={timezoneList}
                                      invalid={errors.timezoneSelected}
                                      onChange={(opt) => {
                                        setTimezoneSelected(opt.value);
                                      }}
                                      value={timezoneList.filter(function (
                                        option
                                      ) {
                                        return (
                                          option.value === timezoneSelected
                                        );
                                      })}
                                    />
                                  )}
                                  <ValidationErrorHandling
                                    error={errors.timezoneSelected}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={12}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    checked={spcificDays}
                                    onClick={() => setSpcificDays(!spcificDays)}
                                  />
                                  <Label
                                    check
                                    onClick={() => setSpcificDays(!spcificDays)}
                                  >
                                    Do you want to send autoresponse on a
                                    specific schedule?
                                  </Label>
                                </FormGroup>
                              </Col>
                              {spcificDays && (
                                <Col sm={12}>
                                  <div className="rounded border shadow p-3 my-3">
                                    {days?.map((data, idx) => (
                                      <>
                                        <Row>
                                          <Col sm={4}>
                                            <FormGroup
                                              check
                                              className="d-flex justify-content-between"
                                            >
                                              <Input
                                                type="checkbox"
                                                id={data?.day}
                                                checked={data?.active}
                                                onChange={(e) =>
                                                  handleSelectedDays(
                                                    idx,
                                                    "active",
                                                    !data.active
                                                  )
                                                }
                                              />
                                              <Label check for={data?.day}>
                                                {data?.name}
                                              </Label>
                                            </FormGroup>
                                          </Col>
                                          <Col sm={4}>
                                            <FormGroup>
                                              <Label for="startTime">
                                                Start Time
                                              </Label>
                                              <TimePicker
                                                disabled={!data.active}
                                                className="w-100"
                                                id="startTime"
                                                format="HH:mm"
                                                value={
                                                  data.active && data.startTime
                                                }
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    idx,
                                                    "startTime",
                                                    e
                                                  )
                                                }
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm={4}>
                                            <FormGroup>
                                              <Label for="endTime">
                                                End Time
                                              </Label>
                                              <TimePicker
                                                disabled={!data.active}
                                                className="w-100"
                                                id="endTime"
                                                format="HH:mm"
                                                value={
                                                  data.active && data.endTime
                                                }
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    idx,
                                                    "endTime",
                                                    e
                                                  )
                                                }
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </>
                                    ))}
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        ),
                      },
                      {
                        tabId: "4",
                        title: "Detail",
                        icon: "images/tabs/detail.png",
                        className: "",
                        component: (
                          <Row>
                            <Col sm={12}>
                              <div className="rounded border shadow p-3 my-3 ">
                                <FormGroup>
                                  <Label for="autoResponder"></Label>

                                  <JoditEditor
                                    ref={editor}
                                    value={autoResponder}
                                    config={config}
                                    onBlur={handleUpdate}
                                  />
                                  {errors.autoResponder && (
                                    <div
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgba(220,53,69,.9)",
                                        width: "fit-content",
                                        position: "static",
                                        marginTop: "3px",
                                        padding: "3px 3px",
                                        borderRadius: "3px",
                                        fontSize: "12px",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {errors.autoResponder}
                                    </div>
                                  )}
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        ),
                      },
                    ]}
                    activeTabindex={activeTabindex}
                  />

                  {/* <div className="p-3 my-3 border rounded shadow">
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="senderName">Sender Name</Label>
                        <Input
                          id="senderName"
                          name="senderName"
                          placeholder="Sender name"
                          type="text"
                          value={senderName}
                          onChange={(e) => {
                            setSenderName(e.target.value);
                          }}
                          autoComplete="off"
                          autoFocus
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={6}>
                      <FormGroup>
                        <Label for="senderEmail">Sender Email</Label>
                        <Input
                          id="senderEmail"
                          name="senderEmail"
                          placeholder="Sender email"
                          type="text"
                          value={senderEmail}
                          onChange={(e) => {
                            setSenderEmail(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="title">Autoresponder Subject Title</Label>
                        <Input
                          id="title"
                          name="title"
                          placeholder="Autoresponder title"
                          type="text"
                          value={autoresponderTitle}
                          onChange={(e) => {
                            setAutoresponderTitle(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="autoresponderName">Internal Autoresponder Name</Label>
                        <Input
                          id="autoresponderName"
                          name="autoresponderName"
                          placeholder="Autoresponder name"
                          type="text"
                          value={autoresponderName}
                          onChange={(e) => {
                            setAutoresponderName(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div> */}

                  {/* <div className="p-3 my-3 border rounded shadow">
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <Label for="timezone">Timezone</Label>
                        {timezoneList && (
                          <Select
                            timezonesLoading={false}
                            options={timezoneList}
                            onChange={(opt) => {
                              setTimezoneSelected(opt.value);
                            }}
                            value={timezoneList.filter(function (
                              option
                            ) {
                              return option.value === timezoneSelected;
                            })}
                          />
                        )}
                      </FormGroup>
                    </Col>

                    <Col sm={12}>
                      <div className="rounded border shadow p-3 my-3 ">
                        <Row>
                          <Col sm={12}>
                            <FormGroup>
                              <legend>
                                <h5>Choose Leads Source Job(s)</h5>
                              </legend>
                              {leadSources &&
                                leadSources.map((job, idx) => {
                                  return (
                                    <FormGroup check>
                                      <InputGroup>
                                        <Input
                                          type="switch"
                                          id={job._id}
                                          onChange={handleLeadSource}
                                          checked={leadSourceId?.includes(
                                            job._id
                                          )}
                                        />
                                        <Label check for={job._id}>{job.jobName}</Label>
                                      </InputGroup>
                                    </FormGroup>
                                  );
                                })}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          checked={spcificDays}
                          onClick={() => setSpcificDays(!spcificDays)}
                        />
                        <Label check onClick={() => setSpcificDays(!spcificDays)}>
                          Do you want to send autoresponse on a specific schedule?
                        </Label>
                      </FormGroup>
                    </Col>
                    {spcificDays &&
                      <Col sm={12}>
                        <div className="rounded border shadow p-3 my-3">
                          {days?.map((data, idx) => (
                            <>
                              <Row>
                                <Col sm={4} >
                                  <FormGroup check className="d-flex justify-content-between" >
                                    <Input
                                      type="checkbox"
                                      id={data?.day}
                                      checked={data?.active}
                                      onChange={(e) => handleSelectedDays(idx, "active", !data.active)}
                                    />
                                    <Label check for={data?.day}>
                                      {data?.name}
                                    </Label>
                                  </FormGroup>

                                </Col>
                                <Col sm={4}>
                                  <FormGroup>
                                    <Label for="startTime">Start Time</Label>
                                    <TimePicker
                                      disabled={!data.active}
                                      className="w-100"
                                      id="startTime"
                                      format="HH:mm"
                                      value={data.active && data.startTime}
                                      onChange={(e) => handleTimeChange(idx, "startTime", e)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={4}>
                                  <FormGroup>
                                    <Label for="endTime">End Time</Label>
                                    <TimePicker
                                      disabled={!data.active}
                                      className="w-100"
                                      id="endTime"
                                      format="HH:mm"

                                      value={data.active && data.endTime}
                                      onChange={(e) => handleTimeChange(idx, "endTime", e)}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          ))}
                        </div>
                      </Col>
                    }
                  </Row>
                </div> */}

                  {/* <Row>
                  <Col sm={12}>
                    <div className="rounded border shadow p-3 my-3 ">
                      <FormGroup>
                        <Label for="autoResponder"></Label>

                        <JoditEditor
                          ref={editor}
                          value={autoResponder}
                          config={config}
                          onBlur={handleUpdate}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row> */}

                  <CardFooter>
                    <FormGroup
                      style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
                    >
                      <SubmitButton
                        isLoading={saveLoading}
                        recordId={recordId}
                        cloneButton={true}
                        cloneButtonOnClick={() =>
                          doCloneAutoresponder(recordId)
                        }
                        cancleButtonOnClick={() => {
                          setShowForm(false);
                          clearForm();
                        }}
                        cancleButton={!showFormInitial}
                      />
                      {/* <Button
                      type="submit"
                      color="primary"
                      onClick={submitForm}

                    >
                      <FontAwesomeIcon icon={faSave} className="mr-2" />
                      Save Changes
                      {saveLoading && (
                        <Spinner
                          className="ml-2"
                          color="light"
                          size="sm"
                          children=""
                        />
                      )}
                    </Button>
                    {recordId &&
                      <Button
                        color="primary"
                        onClick={() => doCloneAutoresponder(recordId)}
                      >
                        <FontAwesomeIcon icon={faClone} className="mr-2" />
                        Clone

                      </Button>
                    }

                    {!showFormInitial && <Button
                      color="secondary"
                      onClick={() => {
                        setShowForm(false);
                        clearForm();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Cancel
                    </Button>} */}
                    </FormGroup>
                  </CardFooter>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
};

export default AutoresponderForm;
