import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Input,
  Form,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Fade
} from "reactstrap";
import { useDispatch } from "react-redux";
import usePost from "../services/usePost";
import { handleYupValidationErrors, showUserNotification, yupValidationSchema } from "../utils/functions";
import ValidationErrorHandling from "./common/validationErrorHandling";
import SubmitButton from "./common/submitButton";

const validationSchema = yupValidationSchema({
  email: { required: true, customName: "Email" }
});

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const { callApi: forgetPassword, isLoading } = usePost({
    endpoint: `/user/forgotpassword-challenge`,
  });

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { email },
        { abortEarly: false }
      );
      setErrors({});
      const check = await forgetPassword({ email }, "Please check your email");
      if (check) {
        dispatch(showUserNotification("Please check your email"));
        setTimeout(() => {
          history.push("/verifypassword?email=" + email);
        }, 2000);
      }
    } catch (err) {
      // dispatch(showUserNotification(err, "danger"));
      setErrors(handleYupValidationErrors(err));
    }
  };
  const changeFormValues = (e) => {
    if (e.target.name === "email") setEmail(e.target.value);
  };
  return (

    <Row className="m-0">
      <Col lg="4" md="3" className="mb-2"></Col>
      <Col lg="4" md="6" className="mb-2 m-4">
        <div className="brand-logo text-center mb-4">
          <img src="/logo.png" alt="Leads Import" width="110px" />
        </div>
        <Fade>
          <Card className="light shadow mt-2 p-4">
            <CardBody>
              <CardTitle tag="h4">
                Forgot Password
              </CardTitle>
              <CardText>
                <Form onSubmit={handleForm}>
                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      name="email"
                      placeholder="Enter your registered email"
                      type="email"
                      value={email}
                      invalid={errors.email}
                      onChange={changeFormValues}
                      autoComplete="off"
                      autoFocus
                      required
                    />
                    <ValidationErrorHandling error={errors.email} />
                  </FormGroup>
                  <div className="mt-3">
                    <SubmitButton
                      buttonClassName="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      type="button"
                      title=" Recover Password"
                      onClick={handleForm}
                      icon={faUnlockAlt}
                      cancleButton={false}
                      isLoading={isLoading}
                    />
                    {/* <button
                    type="button"
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleForm}
                  >
                    <FontAwesomeIcon icon={faUnlockAlt} className="mr-3"/>
                    Recover Password
                    {isLoading && (
                      <Spinner
                        className="ml-2"
                        color="light"
                        size="sm"
                        children=""
                      />
                    )}
                  </button> */}
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <Link to="/" className="auth-link text-primary">
                      Go to Sign In
                    </Link>
                  </div>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </Col>
      <Col lg="4" md="3" className="mb-2"></Col>
    </Row>

  );
};
export default ForgotPassword;
