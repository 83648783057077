import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Button,
  Row,
  Col,
  Input,
  ButtonGroup,
  Fade,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInfo,
  faSave,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import TableRow from "../../common/tableRow";
import usePost from "../../../services/usePost";
import { MSG_ZAPIER } from "../../../utils/message";
import { capitalizeFirstLetter } from "../../../utils/functions";
import { useKey } from "../../../services/useKey";
import Popup from "../../common/popup";
import DataIcons from "../../common/dataIcons";
import SubmitButton from "../../common/submitButton";

const ZapierAccountDetail = ({
  showList,
  setShowList,
  zapierAccountDetail,
}) => {
  const [inputType, setInputType] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [webhookLinks, setWebhookLinks] = useState({});
  const [copyClipboardTextPassword, setCopyClipboardTextPassword] =
    useState(false);
  const [copyClipboardTextEmail, setCopyClipboardTextEmail] = useState(false);

  const { callApi: saveZapierConfigration } = usePost({
    endpoint: `zapier`,
  });

  const { callApi: saveZapierSetusComplete, processSuccess } = usePost({
    endpoint: `zapier/zap/setup/completed`,
  });

  useEffect(() => {
    if (zapierAccountDetail?.destinations) {
      setDestinations((prevDestinations) => [
        ...prevDestinations,
        ...zapierAccountDetail.destinations,
      ]);
    }
  }, [zapierAccountDetail?.destinations]);

  useEffect(() => {
    const updatedLinks = {};

    destinations.forEach((des) => {
      updatedLinks[des.name] = des?.webhookUrl;
    });

    setWebhookLinks((prevWebhookLinks) => ({
      ...prevWebhookLinks,
      ...updatedLinks,
    }));
  }, [destinations]);

  useKey(() => {
    setShowList(true);
  }, "Escape");

  const saveZapierSetup = async (destinationName) => {
    // console.log("destinationName",destinationName)
    await saveZapierConfigration(
      {
        id: zapierAccountDetail._id,
        email: zapierAccountDetail.email,
        password: zapierAccountDetail.password,
        destinations,
      },
      MSG_ZAPIER.SAVE
    );
  };

  const handleZapierSetusComplete = async () => {
    try {
      await saveZapierSetusComplete(
        {
          id: zapierAccountDetail._id,
          status: !zapierAccountDetail.zapCreatedByAdmin,
        },
        "Zapier status updated successfully"
      );
      setShowList(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleWebhookLinkChange = (e, destinationName) => {
    setDestinations((prevDestinations) => {
      return prevDestinations.map((destination) => {
        if (destination.name === destinationName) {
          return {
            ...destination,
            webhookUrl: e.target.value,
          };
        }
        return destination;
      });
    });

    setWebhookLinks({
      ...webhookLinks,
      [destinationName]: e.target.value,
    });
  };

  const togglePassword = () => {
    setInputType(inputType === true ? false : true);
  };

  const toggleCopyIcon = (idx, type) => {
    const copyStateSetter =
      type === true ? setCopyClipboardTextPassword : setCopyClipboardTextEmail;

    copyStateSetter((prevCopyStates) => {
      return { ...prevCopyStates, [idx]: !prevCopyStates[idx] };
    });
  };

  const copyToClipboard = (text, idx, type) => {
    navigator.clipboard.writeText(text);
    toggleCopyIcon(idx, type);
    setTimeout(() => {
      toggleCopyIcon(idx, type);
    }, 1000);
  };
  return (
    <Fade>
      <Card color="shadow" className={`${!showList ? "d-block" : "d-none"}`}>
        <CardBody>
          <CardTitle tag="h4">
            <Row className="justify-content-between">
              <Col md={"auto"} xs={"auto"}>
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                Zapier Account Detail
              </Col>
              <Col md={"auto"} xs={"auto"}>
                <ButtonGroup>
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowList(!showList);
                    }}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faWindowClose} size="sm" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardTitle>
          <Row>
            {/* Customer Information */}
            <Col sm="12" md="6" lg="6">
              <Card color="white shadow" className="mt-3">
                <CardBody>
                  <CardTitle tag="h5">Customer Information</CardTitle>
                  <CardText>
                    <Table
                      responsive
                      striped
                      bordered
                      hover
                      size="sm"
                      className="shadow"
                    >
                      <TableRow
                        label="User"
                        value={zapierAccountDetail?.userDetails?.name}
                      />
                      <TableRow
                        label="Email"
                        value={zapierAccountDetail?.userDetails?.email}
                      />
                      <TableRow
                        label="Phone"
                        value={zapierAccountDetail?.userDetails?.phone}
                      />
                    </Table>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            {/* Customer Zapier Information */}
            <Col sm="12" md="6" lg="6">
              <Card color="white shadow" className="mt-3">
                <CardBody>
                  <CardTitle tag="h5">Customer Zapier Information</CardTitle>
                  <CardText>
                    <Table
                      responsive
                      striped
                      bordered
                      hover
                      size="sm"
                      className="shadow"
                      style={{ marginBottom: "3.125rem" }}
                    >
                      <TableRow
                        label="Email"
                        value={
                          <div className="d-flex justify-content-between align-items-center">
                            {zapierAccountDetail?.email}
                            <FontAwesomeIcon
                              className="mx-2"
                              size="sm"
                              cursor="pointer"
                              title="Copy"
                              onClick={() =>
                                copyToClipboard(
                                  zapierAccountDetail?.email,
                                  zapierAccountDetail?._id,
                                  "email"
                                )
                              }
                              icon={
                                copyClipboardTextEmail[zapierAccountDetail?._id]
                                  ? faCheck
                                  : faCopy
                              }
                            />
                          </div>
                        }
                      />

                      <TableRow
                        label="Password"
                        value={
                          <div className="d-flex justify-content-between">
                            <span
                              style={inputType ? { filter: "blur(5px)" } : {}}
                            >
                              {zapierAccountDetail?.password}
                            </span>
                            {/* {inputType ? "******" : zapierAccountDetail?.password} */}
                            <InputGroup className="d-flex align-items-center justify-content-end">
                              <InputGroupText className="border-left-0 ml-n2 border-0 bg-transparent">
                                <FontAwesomeIcon
                                  title={
                                    inputType === true
                                      ? "Show Password"
                                      : "Hide Password"
                                  }
                                  size="sm"
                                  cursor="pointer"
                                  icon={inputType === true ? faEye : faEyeSlash}
                                  onClick={togglePassword}
                                />
                              </InputGroupText>
                              <FontAwesomeIcon
                                className="mx-1"
                                size="sm"
                                cursor="pointer"
                                title="Copy Password"
                                icon={
                                  copyClipboardTextPassword[
                                    zapierAccountDetail?._id
                                  ]
                                    ? faCheck
                                    : faCopy
                                }
                                onClick={() =>
                                  copyToClipboard(
                                    zapierAccountDetail?.password,
                                    zapierAccountDetail?._id,
                                    "password"
                                  )
                                }
                              />
                            </InputGroup>
                          </div>
                        }
                      />
                    </Table>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Customer Information Below */}
          {zapierAccountDetail?.destinations?.map((destination, idx) => {
            return (
              <Row>
                <Col>
                  <Card color="white shadow" className="mt-3">
                    <CardBody>
                      <CardTitle tag="h5">
                        Customer {capitalizeFirstLetter(destination.name)}{" "}
                        Information
                      </CardTitle>
                      <CardText>
                        <Table
                          responsive
                          striped
                          bordered
                          hover
                          size="sm"
                          className="shadow"
                        >
                          <TableRow
                            label="Email"
                            value={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {destination.email}
                                <FontAwesomeIcon
                                  size="sm"
                                  className="mx-2"
                                  cursor="pointer"
                                  title="Copy Email"
                                  onClick={() =>
                                    copyToClipboard(
                                      destination.email,
                                      idx,
                                      "email"
                                    )
                                  }
                                  icon={
                                    copyClipboardTextEmail[idx]
                                      ? faCheck
                                      : faCopy
                                  }
                                />
                              </div>
                            }
                          />
                          <TableRow
                            label="Password"
                            value={
                              <DataIcons
                                value={destination.password}
                                idx={idx}
                                arrayData={zapierAccountDetail?.destinations}
                                data={zapierAccountDetail}
                              />
                            }
                          />

                          <TableRow
                            label="Enter Zapier webhook link"
                            value={
                              <Input
                                placeholder="Enter zapier webhook link"
                                value={webhookLinks[destination.name] || ""}
                                onChange={(e) =>
                                  handleWebhookLinkChange(e, destination.name)
                                }
                              />
                            }
                          />
                          <TableRow
                            label=" "
                            value={
                              <SubmitButton
                                onClick={() =>
                                  saveZapierSetup(destination.name)
                                }
                                cancleButton={false}
                              />
                            }
                          />
                        </Table>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          })}
          {/* Customer Hubspot Information */}
          <Row>
            <Col>
              <Card color="white shadow" className="mt-3">
                <CardBody>
                  <CardTitle tag="h5"></CardTitle>
                  <CardText>
                    <SubmitButton
                      cancleButton={false}
                      color={
                        zapierAccountDetail?.zapCreatedByAdmin === false
                          ? "primary"
                          : "danger"
                      }
                      title={
                        zapierAccountDetail?.zapCreatedByAdmin === false
                          ? "Complete Zapier Setup"
                          : "Mark Pending"
                      }
                      onClick={() => {
                        if (zapierAccountDetail?.zapCreatedByAdmin === false)
                          setShowModal(true);
                        else handleZapierSetusComplete();
                      }}
                    />
                    {/* <ButtonGroup>
                                            <Button
                                                color={zapierAccountDetail?.zapCreatedByAdmin === false ? "primary" : "danger"}
                                                size="md"
                                                className="mr-2"
                                                onClick={() => {
                                                    if (zapierAccountDetail?.zapCreatedByAdmin === false) setShowModal(true);
                                                    else handleZapierSetusComplete();
                                                }
                                                }
                                                title={zapierAccountDetail?.zapCreatedByAdmin === false ? "Complete Zapier Setup" : "Mark Pending"}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSave}
                                                    size="sm"
                                                /> {zapierAccountDetail?.zapCreatedByAdmin === false ? "Complete Zapier Setup" : "Mark Pending"}
                                            </Button>
                                        </ButtonGroup> */}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showModal && (
        <Popup
          showPopup={showModal}
          hidePopup={() => setShowModal(false)}
          actionMethod={handleZapierSetusComplete}
          title="Zapier Setup Completion"
          promtMessage="Are you sure you want to complete Zapier Setup and notify Customer via Emai?"
        />
      )}
    </Fade>
  );
};

export default ZapierAccountDetail;
