import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    CardFooter,
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Label,
    Fade
} from "reactstrap";
import usePost from '../../../services/usePost';
import { useKey } from '../../../services/useKey';
import { MSG_USER } from '../../../utils/message';
import ValidationErrorHandling from '../../common/validationErrorHandling';
import { handleYupValidationErrors } from '../../../utils/functions';
import SOURCES from '../../../utils/constants';
import SubmitButton from "../../../components/common/submitButton"

const UsersForm = ({ userInfo, setUserInfo, setShowForm, getUsers, paymentPlans, processSuccess, validationSchema }) => {
    const [errors, setErrors] = useState('')
    const [loading, setLoading] = useState(false)

    const { callApi: addUser } = usePost({
        endpoint: `admin/add-user`,
    });

    useEffect(() => {
        if (processSuccess) getUsers();
    }, [processSuccess]);


    useKey(() => {
        setShowForm(false)
    }, "Escape")

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            await validationSchema.validate(userInfo, { abortEarly: false });
            setLoading(true)
            const userAdd = await addUser(
                {
                    email: userInfo.email,
                    password: userInfo.password,
                    confirmPassword: userInfo.confirmPassword,
                    name: userInfo.name,
                    phone: userInfo.phone,
                    company: userInfo.company,
                    paymentStatus: true, // Make sure this is true because we are adding from the admin side
                    paymentPlanId: userInfo.plan,
                },
                MSG_USER.SAVE,
                true
            );
            if (userAdd && userAdd.status === SOURCES.HTTP_CODE_200) {
                setShowForm(false);
                getUsers();
            }
            setErrors({});
        } catch (err) {
            setErrors(handleYupValidationErrors(err));
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fade>
            <Row>
                <Col sm="12" className="mb-2">
                    <Card color="shadow">
                        <CardBody>
                            <CardTitle tag="h4">
                                <FontAwesomeIcon icon={faUser} className="mr-2" />
                                User
                            </CardTitle>
                            <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                            ></CardSubtitle>
                            <CardText>
                                <Form onSubmit={submitForm}>
                                    <div className='border shadow-sm  rounded-lg p-4 my-3' >
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Name</Label>
                                                    <Input
                                                        id="fullName"
                                                        name="fullName"
                                                        type="text"
                                                        placeholder="Enter name"
                                                        className="w-100 p-2 "
                                                        value={userInfo.name}
                                                        onChange={(e) =>
                                                            setUserInfo({ ...userInfo, name: e.target.value })
                                                        }
                                                        invalid={errors.name}
                                                        tabIndex={1}
                                                        autoFocus
                                                    />
                                                    <ValidationErrorHandling error={errors.name} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Company</Label>
                                                    <Input
                                                        id="company"
                                                        name="company"
                                                        type="text"
                                                        placeholder="Enter company name"
                                                        className="w-100 p-2 "
                                                        value={userInfo.company}
                                                        onChange={(e) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                company: e.target.value,
                                                            })
                                                        }
                                                        invalid={errors.company}
                                                        tabIndex={2}
                                                    />
                                                    <ValidationErrorHandling error={errors.company} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Phone</Label>
                                                    <Input
                                                        id="phone"
                                                        name="phone"
                                                        type="text"
                                                        placeholder="Enter phone"
                                                        className="w-100 p-2 "
                                                        value={userInfo.phone}
                                                        onChange={(e) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                phone: e.target.value,
                                                            })
                                                        }
                                                        invalid={errors.phone}
                                                        tabIndex={3}
                                                    />
                                                    <ValidationErrorHandling error={errors.phone} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Email</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        type="text"
                                                        placeholder="Enter email"
                                                        className="w-100 p-2 "
                                                        value={userInfo.email}
                                                        onChange={(e) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                email: e.target.value,
                                                            })
                                                        }
                                                        invalid={errors.email}
                                                        tabIndex={4}
                                                    />
                                                    <ValidationErrorHandling error={errors.email} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Password</Label>
                                                    <Input
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        placeholder="Enter password"
                                                        className="w-100 p-2 "
                                                        value={userInfo.password}
                                                        onChange={(e) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                password: e.target.value,
                                                            })
                                                        }
                                                        invalid={errors.password}
                                                        tabIndex={5}
                                                    />
                                                    <ValidationErrorHandling error={errors.password} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Confirm Password</Label>
                                                    <Input
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        type="password"
                                                        placeholder="Enter confirm password"
                                                        className="w-100 p-2 "
                                                        value={userInfo.confirmPassword}
                                                        onChange={(e) =>
                                                            setUserInfo({
                                                                ...userInfo,
                                                                confirmPassword: e.target.value,
                                                            })
                                                        }
                                                        invalid={errors.confirmPassword}
                                                        tabIndex={6}
                                                    />
                                                    <ValidationErrorHandling error={errors.confirmPassword} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="alertVia">Plan</Label>
                                                    <Input
                                                        name="plan"
                                                        type="select"
                                                        className="form-control form-control-md"
                                                        value={userInfo.plan}
                                                        onChange={(e) =>
                                                            setUserInfo({ ...userInfo, plan: e.target.value })
                                                        }
                                                        invalid={errors.plan}
                                                        tabIndex={7}
                                                    >
                                                        <option value="-1">Choose plan</option>
                                                        {paymentPlans &&
                                                            paymentPlans.map((plan) => {
                                                                return (
                                                                    <option value={plan._id}>
                                                                        {plan.planName}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Input>
                                                    <ValidationErrorHandling error={errors.plan} />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col sm={6}>
                  <FormGroup>
                    <Label for="alertVia">Coupon (if any)</Label>
                    <Input
                      id="coupon"
                      name="coupon"
                      type="text"
                      placeholder="Enter coupon"
                      className="w-100 p-2 "
                      value={userInfo.coupon}
                      onChange={(e) => setUserInfo({...userInfo, coupon: e.target.value})}
                      tabIndex={8}
                    ></Input>
                  </FormGroup>
                </Col> */}
                                        </Row>
                                    </div>

                                    <CardFooter>
                                        <FormGroup>
                                            <SubmitButton
                                                cancleButtonOnClick={() => {
                                                    setShowForm(false);
                                                }}
                                                isLoading={loading}
                                            />
                                            {/* <Button
                                            type="submit"
                                            color="primary"
                                            onClick={submitForm}
                                        >
                                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                                            Save Changes
                                            {isLoading && (
                                                <Spinner
                                                    className="ml-2"
                                                    color="light"
                                                    size="sm"
                                                    children=""
                                                />
                                            )}
                                        </Button>
                                        <Button
                                            color="secondary"
                                            className="ml-2"
                                            onClick={() => {
                                                setShowForm(false);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            Cancel
                                        </Button> */}
                                        </FormGroup>
                                    </CardFooter>
                                </Form>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fade>
    )
}

export default UsersForm;