import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Table,
  Row,
  Col,
  FormGroup,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import usePost from "../../services/usePost";
import { formatDateTime, formatDateTimeFromNow } from "../../utils/functions";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import useDebounce from "../../utils/customHooks";
import NoRecordsFound from "../common/noRecordsFound";
import TableLoader from "../common/tableLoader";
import TableHeader from "../common/tableHeader";
import HeaderSearchInput from "../common/headerSearchInput";

const Notificaions = () => {
  const [id, setId] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });

  const [notifications, setNotifications] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);


  const {
    callApi: getNotifications,
    data: notificationsList,
    isLoading: notificationsLoading,
  } = useGet({
    endpoint: `notification/list?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `notification/search?q=${searchInput}`,
  });

  const { callApi: notificationUnread, processSuccess } = usePost({
    endpoint: `notification/status`,
  });

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
      else if (!debouncedSearchTerm) getNotifications();
    },
    [debouncedSearchTerm]
  );

  useEffect(() => {
    getNotifications();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    if (id) notificationUnread({ id });
  }, [id]);

  useEffect(() => {
    if (processSuccess) getNotifications();
  }, [processSuccess]);

  useEffect(() => {
    setNotifications(notificationsList);
  }, [notificationsList]);

  const viewNotification = (item) => {
    if (item.readStatus === SOURCES.NOTIFICATION_UNREAD) {
      setId(item._id);
    }
  };

  useEffect(() => {
    setNotifications(searchData);
  }, [searchData]);


  const sortData = (title) => {
    setSortBy(title)
    setSortOrder(sortOrder === 1 ? -1 : 1)
  }

  const dataExist = notifications && notifications.notifications.length > 0 ? true : false;
  const isLoading = notificationsLoading | searchDataLoading

  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle tag="h4">
            <Row>
              <Col sm={9} style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={faBell} className="mr-2" />
                Notifications
              </Col>
              <Col sm={3}>
                <HeaderSearchInput
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Col>
            </Row>
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
          </CardSubtitle>
          <CardText>
            <Table responsive striped bordered hover size="sm" className="shadow">
              <TableHeader
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
                headers={[
                  {
                    title: 'Notification'
                    , showIcon: true
                  },
                  {
                    title: 'Date'
                    , showIcon: true
                  },
                  {
                    title: 'Status'
                    , showIcon: true
                  }
                ]} />
              <tbody>
                {isLoading ?
                  <TableLoader colSpan="3" />
                  :
                  <>
                    {
                      dataExist
                        ?
                        notifications.notifications.map((item, idx) => {
                          return (
                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                              <td>{item.notifyText}</td>
                              <td>
                                {formatDateTime(item.createdAt)}
                                <br />
                                <small>
                                  {formatDateTimeFromNow(item.createdAt)}
                                </small>
                              </td>
                              <td>
                                {item.readStatus === SOURCES.NOTIFICATION_UNREAD ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="mr-2 btn btn-primary"
                                    onClick={() => viewNotification(item)}
                                  />
                                ) : (
                                  item.readStatus
                                )}
                              </td>
                            </tr>
                          );
                        })
                        :
                        <NoRecordsFound colSpan="3" />
                    }
                  </>
                }
              </tbody>
            </Table>
          </CardText>
        </CardBody>
        <div className="px-4">
          {!searchInput && notifications?.count > SOURCES.DEFAULT_RECORDS_LISTING && (
            <FormGroup>
              <Paginate
                setPagination={setPagination}
                totalRecords={notifications?.count}
                isLoading={isLoading}
              />
            </FormGroup>
          )}
        </div>
      </Card>
    </Fade>
  );
};

export default Notificaions;
