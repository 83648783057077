import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faWrench } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  Col,
  Fade,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../utils/functions";
import { MSG_ZAPIER } from "../utils/message";
import usePost from "../services/usePost";
import useGet from "../services/useGet";
import DestinationFooter from "./forms/destinations/common/destinationFooter";
import Header from "./common/header";
import ValidationErrorHandling from "./common/validationErrorHandling";
import SOURCES from "../utils/constants";

const validationSchema = yupValidationSchema({
  email: { required: true, customName: "Email" },
  password: { required: true, customName: "Password" },
});

const ZapierConfigration = () => {
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [destinations, setDestinations] = useState([]);

  const [emailHoneyBook, setEmailHoneyBook] = useState("");
  const [passwordHoneyBook, setPasswordHoneyBook] = useState("");
  const [passwordTypeHoneyBook, setPasswordTypeHoneyBook] =
    useState("password");

  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [errors, setErrors] = useState({});

  const { callApi: saveZapierConfigration } = usePost({
    endpoint: `zapier`,
  });

  const { callApi: getZapierConfigration, data: zapierData } = useGet({
    endpoint: `zapier`,
  });

  useEffect(() => {
    getZapierConfigration();
  }, []);

  useEffect(() => {
    if (zapierData && !id) {
      setId(zapierData?._id);
      setEmail(zapierData?.email);
      setPassword(zapierData?.password);
      zapierData?.destinations &&
        zapierData.destinations.map((dest) => {
          if (dest.name === SOURCES.DESTINATION_HONEYBOOK) {
            setEmailHoneyBook(dest.email);
            setPasswordHoneyBook(dest.password);
          }
        });
    }
  }, [zapierData]);

  useEffect(() => {
    const existingDestinationIndex = destinations.findIndex(
      (destination) => destination.name === SOURCES.DESTINATION_HONEYBOOK
    );

    if (existingDestinationIndex !== -1) {
      const updatedDestinations = [...destinations];
      updatedDestinations[existingDestinationIndex] = {
        ...updatedDestinations[existingDestinationIndex],
        email: emailHoneyBook,
        password: passwordHoneyBook,
      };
      setDestinations(updatedDestinations);
    } else {
      setDestinations([
        ...destinations,
        {
          name: SOURCES.DESTINATION_HONEYBOOK,
          email: emailHoneyBook,
          password: passwordHoneyBook,
        },
      ]);
    }
  }, [emailHoneyBook, passwordHoneyBook]);

  const handelZapierConfigration = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(
        { email, password },
        { abortEarly: false }
      );
      setLoading(true);
      await saveZapierConfigration(
        {
          id,
          email,
          password,
          destinations,
        },
        MSG_ZAPIER.SAVE
      );
      getZapierConfigration();
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  const togglePassword = (destination = null) => {
    switch (destination) {
      default:
        if (passwordType === "password") setPasswordType("text");
        else setPasswordType("password");
        break;
      case SOURCES.DESTINATION_HONEYBOOK:
        if (passwordTypeHoneyBook === "password")
          setPasswordTypeHoneyBook("text");
        else setPasswordTypeHoneyBook("password");
        break;
    }
  };

  return (
    <Fade>
      <Card>
        <CardBody>
          <Header icon={faWrench} title="Zapier Configration" />
          <Card className="shadow">
            <CardBody>
              <Form onSubmit={handelZapierConfigration} autoComplete="off">
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="email">Zapier Account Email</Label>
                      <Input
                        placeholder="Zapier account email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={errors.email}
                        autoFocus
                      />
                      <ValidationErrorHandling error={errors.email} />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="password">Zapier Account Password</Label>
                      <InputGroup>
                        <Input
                          placeholder="Zapier account password"
                          type={passwordType}
                          autoComplete="new-password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          invalid={errors.password}
                        />
                        <InputGroupText
                          style={{ borderLeft: "0px", marginLeft: "-3px" }}
                        >
                          <FontAwesomeIcon
                            icon={
                              passwordType === "password" ? faEyeSlash : faEye
                            }
                            onClick={togglePassword}
                          />
                        </InputGroupText>
                      </InputGroup>
                      <ValidationErrorHandling error={errors.password} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <FormGroup>
                      <Label>
                        <img src="images/honeybook.png" alt="" width="30px" />
                        <strong>HoneyBook Account Credentials</strong>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="email">Account Email</Label>
                      <Input
                        placeholder="Account email"
                        type="email"
                        value={emailHoneyBook}
                        onChange={(e) => setEmailHoneyBook(e.target.value)}
                        invalid={errors.emailHoneyBook}
                      />
                      <ValidationErrorHandling error={errors.emailHoneyBook} />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="password">Account Password</Label>
                      <InputGroup>
                        <Input
                          placeholder="Account password"
                          type={passwordTypeHoneyBook}
                          autoComplete="new-password"
                          value={passwordHoneyBook}
                          onChange={(e) => setPasswordHoneyBook(e.target.value)}
                          invalid={errors.passwordHoneyBook}
                        />
                        <InputGroupText
                          style={{ borderLeft: "0px", marginLeft: "-3px" }}
                        >
                          <FontAwesomeIcon
                            icon={
                              passwordTypeHoneyBook === "password"
                                ? faEyeSlash
                                : faEye
                            }
                            onClick={() =>
                              togglePassword(SOURCES.DESTINATION_HONEYBOOK)
                            }
                          />
                        </InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <FormText color="info">
                        <strong>Note:</strong>We will inform you once{" "}
                        <strong>Zapier</strong> setup has been completed by our
                        team.
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>

                <DestinationFooter
                  handleSubmit={handelZapierConfigration}
                  loading={loading}
                />
              </Form>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default ZapierConfigration;
