import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheckCircle, faSignInAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Badge } from "reactstrap";
import { MSG_USER } from "../../../utils/message";
import SOURCES from "../../../utils/constants";
import TableHeader from "../../common/tableHeader";
import TableLoader from "../../common/tableLoader";
import Popup from "../../common/popup";
import NoRecordsFound from "../../common/noRecordsFound";

const UserList = ({
    isLoading,
    userList,
    setshowDeatail,
    showDetail,
    setLeadDetail,
    getUsers,
    getSearchData,
    searchInput,
    accountStatus,
    loginAsUser,
    deleteUser,
    deleteId,
    removeSuccess,
    setDeleteId,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder,
    testAccount=false,
    handleRemoveTestAccount = null
}) => {


    const [toggleModal, setToggleModal] = useState(false);

    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getUsers();
        }
    }, [removeSuccess]);

    const showHideDetail = (data) => {
        setshowDeatail(!showDetail);
        setLeadDetail(data);
    }

    const changeUserStatus = async (email, userStatus) => {
        await accountStatus({ email, userStatus }, MSG_USER.UPDATE);
        if (searchInput) await getSearchData()
        else await getUsers()
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    return (
      <>
        <Table responsive striped bordered hover size="sm" className="shadow">
          <TableHeader
            headers={[
              { title: "Name", showIcon: true },
              { title: "Email", showIcon: true },
              { title: "Phone", showIcon: true },
              { title: "Company", showIcon: true },
              { title: "Verified", showIcon: true },
              { title: "Plan", showIcon: true },
              { title: "Trial", showIcon: true },
              { title: "" },
              { title: "" },
            ]}
            sortData={sortData}
            setSortBy={setSortBy}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
          <tbody>
            {isLoading ? (
              <TableLoader colSpan="8" />
            ) : (
              <>
                {userList && userList.length > 0 ? (
                  userList?.map((item, idx) => {
                    return (
                      <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                        <td>
                          {!testAccount ? (
                            <Link
                              to="#"
                              className="auth-link text-black"
                              onClick={() => showHideDetail(item)}
                            >
                              {item?.name}
                            </Link>
                          ) : (
                            <span>{item?.name}</span>
                          )}
                        </td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.company}</td>
                        <td>
                          {item?.verified === "n" ? (
                            <Badge color="danger">Not Verified</Badge>
                          ) : (
                            <Badge color="success">Verified</Badge>
                          )}
                        </td>
                        <td>{item?.PlanInformation?.planName}</td>
                        <td>{item?.isTrial === "y" ? <Badge color="danger">Yes</Badge> : ""}</td>
                        <td>
                          {item?.userRole !== SOURCES.USER_ROLE_ADMIN && item.verified === "y" && (
                            <Button color="primary" onClick={() => loginAsUser(item.email)}>
                              <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                              Login
                            </Button>
                          )}
                        </td>
                        {testAccount ? (
                          <td>
                            <Button color="danger" onClick={(e) => handleRemoveTestAccount(item._id)}>
                              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                              Remove From Test
                            </Button>
                          </td>
                        ) : (
                          <td>
                            {item?.userRole !== SOURCES.USER_ROLE_ADMIN &&
                              item.verified === "y" &&
                              (item.userStatus === "active" ? (
                                <Button
                                  color="secondary"
                                  onClick={() => changeUserStatus(item.email, SOURCES.USER_STATUS_SUSPENDED)}
                                >
                                  <FontAwesomeIcon icon={faBan} className="mr-2" />
                                  Suspend
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  onClick={() => changeUserStatus(item.email, SOURCES.USER_STATUS_ACTIVE)}
                                >
                                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                  Activate
                                </Button>
                              ))}

                            {item?.userRole !== SOURCES.USER_ROLE_ADMIN && item.verified === "n" && (
                              <Button
                                color="danger"
                                onClick={() => {
                                  setDeleteId(item._id);
                                  setToggleModal(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                Delete
                              </Button>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <NoRecordsFound colSpan={"8"} />
                )}
              </>
            )}
          </tbody>
        </Table>
        {toggleModal && (
          <Popup
            showPopup={toggleModal}
            hidePopup={() => setToggleModal(false)}
            actionMethod={() => deleteUser(deleteId)}
            title="Delete User"
            promtMessage="Are you sure you want to delete User?"
          />
        )}
      </>
    );
}
export default UserList