import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import Layout from '../layout';
import Login from '../components/login';
import Signup from '../components/signup';
import Stripe from '../components/stripe/index';
import Payment from '../components/payment';
import Dashbord from '../components/dashbord';
import Profile from '../components/profile';
import Settings from "../components/settings"
import ChangePassword from '../components/changePassword';
import ProtectedRoutes from './middleware';
import Destinations from '../components/destinations';
import LeadSources from '../components/leadSources';
import ForgotPassword from '../components/forgotPassword';
import VerifyPassword from '../components/verifyPassword';
import Autoresponder from '../components/autoresponders';
import AlertEmailSms from '../components/alertEmailSms';
import CancelSubscription from '../components/cancelSubscription';
import VerifyAccount from '../components/verifyAccount';
import LeadsImportCsv from '../components/leadsImportCsv';

/**
 * ADMIN ROUTES
 */
import AdminUsers from '../components/admin/users';
import PaymentInvoice from '../components/paymentInvoice';
import AdminLeadsImported from '../components/admin/leadsImported';

import AdminCoupons from '../components/admin/coupons';
import AdminAffiliate from '../components/admin/affiliate';
import AdminScrappingErrors from '../components/admin/scrappingErrors';
import AdminEmailLogs from '../components/admin/emailLogs';
import AdminCancelledSubscriptions from '../components/admin/cancelledSubscriptions';
import AdminChangeUserEmail from '../components/admin/changeUserEmail';
import AdminPaymentHistory from '../components/admin/paymentHistory';
import AdminZapierAccounts from '../components/admin/zapierAccounts';
import AdminDataBaseBackup from '../components/admin/dataBaseBackup';
import AdminIncomeReport from '../components/admin/incomeReport';

/**
 * ADMIN REPORTS ROUTES
 */
import NoLeadsSend from '../components/admin/reports/noLeadsSend';



/**
 * REPORTS ROUTES
 */
import LeadsImported from '../components/reports/leadsImported';
import LeadSentHistory from '../components/reports/leadSentHistory';
import AutoresponseSent from '../components/reports/autoresponseSent';
import Notificaions from '../components/reports/notifications';
import PaymentHistory from '../components/reports/paymentHistory';
import Invoices from '../components/reports/invoices';
import LeadsImportedViaCSV from '../components/reports/leadsImportedViaCSV';
import hotLeads from '../components/reports/hotLeads';
import WebLink from '../components/webLink';
import LeadGenerateForm from '../components/leadGenerateForm';
import LeadsImportedViaForm from '../components/reports/leadsImportedViaForm';
import ActivityLog from '../components/activityLog';
import Affiliate from '../components/affiliate';
import StripeOnBoard from '../components/stripeOnBoard';
import AffiliateDashboard from '../components/affiliateDashboard';
import Customers from '../components/reports/AffiliateCustomers';
import ZapierConfigration from '../components/zapierConfigration';
import TestAccounts from '../components/admin/TestAccounts';
import NewLeadAlert from '../components/reports/newLeadAlert';

const AppRoutes = () => {

  useEffect(() => {
    document.title = `Leads Import`
  }, [])
  return (
    <Router>
      <Switch>
        <Route path={"/"} exact>
          {" "}
          <Login />{" "}
        </Route>
        <Route path="/signup">
          <Signup />{" "}
        </Route>
        <Route path="/affiliate">
          <Affiliate />
        </Route>
        <Route path="/signup/trial">
          <Signup />{" "}
        </Route>
        <Route path="/forgotpassword">
          <ForgotPassword />{" "}
        </Route>
        <Route path="/verifypassword">
          <VerifyPassword />{" "}
        </Route>
        <Route path="/verifyaccount">
          <VerifyAccount />{" "}
        </Route>

        <Route path="/stripeonboard">
          <StripeOnBoard />
        </Route>
        <Route path="/payment-signup">
          <Stripe />
        </Route>
        <Route path="/weblink/present/webform">
          <LeadGenerateForm />
        </Route>

        <ProtectedRoutes path="/payment" component={Payment} layout={Layout} />
        <ProtectedRoutes path="/paymentInvoice" component={PaymentInvoice} layout={Layout} />
        <ProtectedRoutes path="/invoices" component={Invoices} layout={Layout} />
        <ProtectedRoutes path="/paymentHistory" component={PaymentHistory} layout={Layout} />
        <ProtectedRoutes path="/leadsImported" component={LeadsImported} layout={Layout} />
        <ProtectedRoutes path="/leadsImport-Csv" component={LeadsImportCsv} layout={Layout} />
        <ProtectedRoutes path="/webLink" component={WebLink} layout={Layout} />

        <ProtectedRoutes path="/leadsSent" component={LeadSentHistory} layout={Layout} />
        <ProtectedRoutes path="/notifications" component={Notificaions} layout={Layout} />
        <ProtectedRoutes path="/autoresponseSent" component={AutoresponseSent} layout={Layout} />
        <ProtectedRoutes path="/activityLog" component={ActivityLog} layout={Layout} />
        <ProtectedRoutes path="/newLeadAlert" component={NewLeadAlert} layout={Layout} />
        <ProtectedRoutes path="/leadsImportedViaCsv" component={LeadsImportedViaCSV} layout={Layout} />
        <ProtectedRoutes path="/leadsImportedViaForm" component={LeadsImportedViaForm} layout={Layout} />
        <ProtectedRoutes path="/hotLeads" component={hotLeads} layout={Layout} />

        <ProtectedRoutes path="/dashboard" component={Dashbord} layout={Layout} />
        <ProtectedRoutes path="/dashboard-affiliate" component={AffiliateDashboard} layout={Layout} />
        <ProtectedRoutes path="/customers" component={Customers} layout={Layout} />
        <ProtectedRoutes path="/profile" component={Profile} layout={Layout} />
        <ProtectedRoutes path="/settings" component={Settings} layout={Layout} />
        <ProtectedRoutes path="/changepassword" component={ChangePassword} layout={Layout} />
        <ProtectedRoutes path="/zapierConfigration" component={ZapierConfigration} layout={Layout} />
        <ProtectedRoutes path="/destinations" component={Destinations} layout={Layout} />
        <ProtectedRoutes path="/leadsources" component={LeadSources} layout={Layout} />
        <ProtectedRoutes path="/autoresponders" component={Autoresponder} layout={Layout} />
        <ProtectedRoutes path="/alerts" component={AlertEmailSms} layout={Layout} />
        <ProtectedRoutes path="/cancel-subscription" component={CancelSubscription} layout={Layout} />

        <ProtectedRoutes path="/admin/users" component={AdminUsers} layout={Layout} />
        <ProtectedRoutes path="/admin/testaccounts" component={TestAccounts} layout={Layout} />

        <ProtectedRoutes path="/admin/leadsImported" component={AdminLeadsImported} layout={Layout} />
        <ProtectedRoutes path="/admin/coupons" component={AdminCoupons} layout={Layout} />
        <ProtectedRoutes path="/admin/affiliate" component={AdminAffiliate} layout={Layout} />
        <ProtectedRoutes path="/admin/zapierAccounts" component={AdminZapierAccounts} layout={Layout} />
        <ProtectedRoutes path="/admin/scrappingerrors" component={AdminScrappingErrors} layout={Layout} />
        <ProtectedRoutes path="/admin/emaillogs" component={AdminEmailLogs} layout={Layout} />
        <ProtectedRoutes path="/admin/paymenthistory" component={AdminPaymentHistory} layout={Layout} />
        <ProtectedRoutes
          path="/admin/cancelled/subscriptions"
          component={AdminCancelledSubscriptions}
          layout={Layout}
        />
        <ProtectedRoutes path="/admin/changeuseremail" component={AdminChangeUserEmail} layout={Layout} />
        <ProtectedRoutes path="/admin/leads-not-send" component={NoLeadsSend} layout={Layout} />
        <ProtectedRoutes path="/admin/dataBaseBackup" component={AdminDataBaseBackup} layout={Layout} />
        <ProtectedRoutes path="/admin/incomereport" component={AdminIncomeReport} layout={Layout} />
      </Switch>
    </Router>
  );

}
export default AppRoutes