import { CardFooter} from "reactstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../../../common/submitButton";

const DestinationFooter = ({ handleSubmit, loading }) => {
    return (
        <CardFooter>
            <SubmitButton
                type="submit"
                color="primary"
                title=" Save Changes"
                onClick={handleSubmit}
                icon={faSave}
                isLoading={loading}
                cancleButton={false}
            />

        </CardFooter>
    );
}

export default DestinationFooter;