import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CardFooter,
  Fade,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import SOURCES from "../../utils/constants";
import usePost from "../../services/usePost";
import { capitalizeFirstLetter } from "../../utils/functions";
import { useKey } from "../../services/useKey";
import { MSG_ALERT } from "../../utils/message";
import ValidationErrorHandling from "../common/validationErrorHandling";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../utils/functions";
import SubmitButton from "../common/submitButton";

const validationSchema = yupValidationSchema({
  alertVia: { required: true, customName: "Alert source" },
  alertName: { required: true, customName: "Alert name" },
  alertEmail: { required: true, email: true, customName: "Alert email" },
  alertSms: { required: true, customName: "Alert mobile no" },
});

const AlertEmailSmsForm = ({
  parentMethod,
  setId,
  setShowForm,
  id,
  showFormInitial,
  getAlerts,
  alert,
}) => {
  const [alertVia, setAlertVia] = useState("");
  const [alertName, setAlertName] = useState("");
  const [alertEmail, setAlertEmail] = useState("");
  const [alertSms, setAlertSms] = useState("");
  const [errors, setErrors] = useState("");
  const { ALERT_VIA_EMAIL, ALERT_VIA_SMS, ALERT_VIA_BOTH } = SOURCES;

  const {
    callApi: saveAlert,
    isLoading,
    processSuccess,
  } = usePost({ endpoint: `alert` });

  useEffect(() => {
    if (alert && id) {
      if (parentMethod) parentMethod();
      else {
        setAlertVia(alert?.alertVia);
        setAlertEmail(alert?.alertEmail);
        setAlertName(alert?.alertName);
        setAlertSms(alert?.alertSms);
        setShowForm(true);
      }
    }
    if (processSuccess) {
      if (parentMethod) parentMethod();
      else {
        clearForm();
        getAlerts();
      }
    }
  }, [alert, processSuccess]);

  const clearForm = () => {
    if (parentMethod) parentMethod();
    else {
      setId("");
      setAlertVia("");
      setAlertName("");
      setAlertEmail("");
      setAlertSms("");
      setShowForm(false);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          alertVia,
          alertName,
          alertEmail,
          alertSms,
        },
        { abortEarly: false }
      );
      const postData = {
        id,
        alertVia,
        alertName,
        alertEmail,
        alertSms,
      };
      saveAlert(postData, MSG_ALERT.SAVE);
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    }
  };

  useKey(() => {
    setShowForm(false);
    clearForm();
  }, "Escape");

  useKey(submitForm(), "Enter");

  return (
    <Fade>
      <Row>
        <Col sm="12" className="mb-2">
          <Card color="shadow ">
            <CardBody>
              <CardTitle tag="h4">
                <FontAwesomeIcon icon={faBell} className="mr-2" />
                Email/Sms Alerts
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
              <CardText>
                <div className="rounded border shadow p-3 my-3">
                  <Form onSubmit={submitForm}>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Alert Via</Label>
                          <Input
                            id="leadSource"
                            name="select"
                            type="select"
                            invalid={errors.alertVia}
                            className="w-100 p-2 "
                            onChange={(e) => setAlertVia(e.target.value)}
                            tabIndex={1}
                            autoFocus
                          >
                            <option value="">Select one</option>

                            <option
                              value={ALERT_VIA_EMAIL}
                              selected={ALERT_VIA_EMAIL === alertVia}
                            >
                              {capitalizeFirstLetter(ALERT_VIA_EMAIL)}
                            </option>
                            <option
                              value={ALERT_VIA_SMS}
                              selected={ALERT_VIA_SMS === alertVia}
                            >
                              {capitalizeFirstLetter(ALERT_VIA_SMS)}
                            </option>
                            <option
                              value={ALERT_VIA_BOTH}
                              selected={ALERT_VIA_BOTH === alertVia}
                            >
                              {capitalizeFirstLetter(ALERT_VIA_EMAIL) +
                                " & " +
                                capitalizeFirstLetter(ALERT_VIA_SMS)}
                            </option>
                          </Input>
                          <ValidationErrorHandling error={errors.alertVia} />
                        </FormGroup>
                      </Col>

                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Name</Label>
                          <Input
                            id="name"
                            name="name"
                            placeholder="Enter name"
                            type="text"
                            onChange={(e) => setAlertName(e.target.value)}
                            invalid={errors.alertName}
                            value={alertName}
                          />
                          <ValidationErrorHandling error={errors.alertName} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            type="text"
                            invalid={errors.alertEmail}
                            onChange={(e) => setAlertEmail(e.target.value)}
                            value={alertEmail}
                          />
                          <ValidationErrorHandling error={errors.alertEmail} />
                        </FormGroup>
                      </Col>

                      <Col sm={6}>
                        <FormGroup>
                          <Label for="alertVia">Mobile No</Label>
                          <Input
                            id="sms"
                            name="sms"
                            placeholder="Enter mobile no"
                            type="text"
                            onChange={(e) => setAlertSms(e.target.value)}
                            invalid={errors.alertSms}
                            value={alertSms}
                          />
                          <ValidationErrorHandling error={errors.alertSms} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <CardFooter>
                      <FormGroup>
                        <SubmitButton
                          isLoading={isLoading}
                          cancleButtonOnClick={() => {
                            setShowForm(false);
                            clearForm();
                          }}
                          cancleButton={!showFormInitial}
                        />
                        {/* <Button
                                                type="submit"
                                                color="primary"
                                                onClick={submitForm}
                                                >
                                               <FontAwesomeIcon icon={faSave} className="mr-2" />
                                                Save Changes
                                                {isLoading && (
                                                    <Spinner
                                                        className="ml-2"
                                                        color="light"
                                                        size="sm"
                                                        children=""
                                                    />
                                                )}
                                            </Button>
                                            {!showFormInitial &&
                                                <Button
                                                    color="secondary"
                                                    className="ml-2"
                                                    onClick={() => {
                                                        setShowForm(false);
                                                        clearForm();
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                                    Cancel
                                                </Button>
                                            } */}
                      </FormGroup>
                    </CardFooter>
                  </Form>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
};
export default AlertEmailSmsForm;
