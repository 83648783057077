import React from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
// import { interceptor } from "../../utils/interceptor";
// import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { showUserNotification } from "../../utils/functions";

// const axiosInstance = interceptor();
// const amount = process.env.REACT_APP_SUBSCRIPTION_CHARGES;

const CheckoutForm = ({ stripe, elements }) => {
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const [loading, setLoading] = useState(false);
  // const [cardHolder, setCardHolder] = useState("");  

  /*const handleSubmit = async (event) => {
    event.preventDefault();

    // const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      // console.log("no stripe");
      return;
    }
    setLoading(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setLoading(false);
      console.log(result.error.message);
    } else {
      // pass the token to your backend API
      const cardToken = result.token;
      try {
        await axiosInstance.post("/payment/stripe", {
          cardToken,
          cardHolderName: cardHolder,
          // amount: amount,
        });
        setLoading(false);
        dispatch(showUserNotification("Transaction completed"));
        history.push("/profile");
      } catch (err) {
        setLoading(false);
        dispatch(showUserNotification("Transaction failed","danger"));
        
      }
    }
  };//*/

  return (
    <>
      {/* <div class="product-info">
        <h3 className="product-title">Payment Details</h3>
      </div> */}
      {/* <form onSubmit={handleSubmit}> */}
        {/* <FormGroup>
          <Label for="name" className="ml-2 text-left w-100">
            Card Holder Details
          </Label>
          <Input
            id="name"
            name="name"
            placeholder="Card holder name"
            type="text"
            onChange={(e) => {
              setCardHolder(e.target.value);
            }}
            value={cardHolder}
            autoFocus
          />
        </FormGroup> */}
        <CardSection />
        {/* <FormGroup className="d-flex">
          <Label for="name" className="ml-2 text-left w-100">
            Total Amount
          </Label>
          <Label className="product-price">${amount}</Label>
        </FormGroup> */}

        {/* <button className="btn btn-primary btn-pay" disabled={loading}>Pay
          { loading && <Spinner className="ml-2" color="light" size="sm" children=""/>}
        </button> */}
        
      {/* </form> */}
    </>
  );
};

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements}  style={{margin:"0px"}}/>
      )}
    </ElementsConsumer>
  );
}

export default InjectedCheckoutForm;
