import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faFilter } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, CardTitle, CardSubtitle, CardText, FormGroup, Row, Col, Button, Collapse, Fade } from "reactstrap";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import ScrappingErrorList from "./modules/scrappingErrorList";
import ScrappingErrorDetail from "./modules/scrappingErrorDetail";
import AdvanceSearch from "../common/advanceSearch";
import PaginateSearch from "../common/paginationSearch";

const ScrappingErrors = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });
  const [paginationSearch, setPaginationSearch] = useState({
    offsetSearch: 0,
    limitSearch: SOURCES.DEFAULT_RECORDS_SEARCH
  });
  const [scrapErrors, setScrapErrors] = useState();
  const [errorDetail, setErrorDetail] = useState(null);
  const [showDetail, setshowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
  const [advanceSearch, setAdvanceSearch] = useState({
    advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    startDate: "",
    endDate: "",
    leadSource: "",
    user: "",
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `admin/scraperrors/search?startDate=${advanceSearch.startDate}&endDate=${advanceSearch.endDate}&leadSource=${advanceSearch.leadSource}&userEmail=${advanceSearch.user}`,
  });

  const { callApi: getLeadSourcesOptions, data: leadSource, isLoading: getLeadSourcesOptionsLoader } = useGet({
    endpoint: `leadsource/options`,
  });

  const {
    callApi: getScrapErrors,
    data: scrapErrorsList,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `admin/scraperrors?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const isLoading = searchDataLoading || getLeadsLoading || getLeadSourcesOptionsLoader;

  useEffect(() => {
    if (advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER || advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM) {
      getScrapErrors();
      getLeadSourcesOptions();
      if (advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM) setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
    }
  }, [pagination.offset, pagination.limit, advanceSearch.advance_search_enabled, sortBy, sortOrder]);

  useEffect(() => {
    setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
    setShowAdvanceSearch(false);
  }, [searchInput])

  useEffect(() => {
    if (advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER || advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT) getSearchData();
  }, [paginationSearch.offsetSearch, paginationSearch.limitSearch]);

  useEffect(() => {
    setScrapErrors(scrapErrorsList);
  }, [scrapErrorsList]);


  useEffect(() => {
    setScrapErrors(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setErrorDetail(item);
    setshowDetail(true);
  };

  return (
    <div>
      <Fade>
      <Card color="shadow" className={`${showDetail ? 'd-none' : 'd-block'}`}>
        <CardBody>
          <CardTitle >
            <Row>
              <Col lg={9} tag={"h4"}>
                <FontAwesomeIcon icon={faBug} className="mr-2" />
                Scrapping Errors
              </Col>
              <Col lg={3}>
                <Button
                  color="primary"
                  onClick={() => {
                    setShowAdvanceSearch(true);
                  }}
                  style={{
                    minWidth: "100%"
                  }}
                >
                  <FontAwesomeIcon icon={faFilter} className="mr-2"/>
                  Advance Search
                </Button>

                <Collapse
                  style={{ marginTop: "8px" }}
                  isOpen={showAdvanceSearch}
                  delay={{
                    show: 5000,
                    hide: 5000
                  }}>
                  <AdvanceSearch
                    searchInput={searchInput}
                    isOpen={showAdvanceSearch}
                    advanceSearch={advanceSearch}
                    setShowAdvanceSearch={setShowAdvanceSearch}
                    setAdvanceSearch={setAdvanceSearch}
                    getSearchData={getSearchData}
                    scrappingUsers={scrapErrorsList}
                    filteredData={{ key: "LEAD-SOURCES", data: leadSource, hideTermInput: true }}
                  />

                </Collapse>
              </Col>
            </Row>
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
          <CardText>
            <ScrappingErrorList
              scrapErrors={scrapErrors}
              isLoading={isLoading}
              viewDetail={viewDetail}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
          </CardText>
        </CardBody>
        <div className="px-4">
          {!searchInput && [SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0 && scrapErrors?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
            <FormGroup>
              <Paginate
                setPagination={setPagination}
                totalRecords={scrapErrors?.totalRecords}
                isLoading={isLoading}
              />
            </FormGroup>
          )}

          {
            ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0 || searchInput) && scrapErrors?.totalRecords > SOURCES.DEFAULT_RECORDS_SEARCH &&
            <FormGroup>
              <PaginateSearch
                setPaginationSearch={setPaginationSearch}
                totalRecords={scrapErrors?.totalReords}
              />
            </FormGroup>
          }
        </div>
      </Card>
      </Fade>
      {errorDetail &&
        <ScrappingErrorDetail
          errorDetail={errorDetail}
          showDetail={showDetail}
          setshowDetail={setshowDetail}
        />
      }
    </div>
  );
};

export default ScrappingErrors;